import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";

import ListPageDX from "../components/business/listpagedx";
import { useNotificationContext } from "../context/notificationcontext";
import useOrganizationRoleService from "../shared/services/userroleservice";
import UserRoleModal from "../components/editmodals/userrolemodal";

const UserRoles = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const { getOrganizationRoles, deleteOrganizationRole } =
    useOrganizationRoleService();

  const [userRoleId, setUserRoleId] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: languageIsEn ? "parentRoleEnName" : "parentRoleArName",
      headerName: `${t("Parent Role")}`,
      flex: 1,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getOrganizationRoles() // api to fetch data types from the server
      .then((response) => {
        setDataFromApi(response);
        setRows(response);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteOrganizationRole(id)
      .then(() => {
        setInfo(t("User Role deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (role: any) => {
    setUserRoleId(role.organizationRoleId);
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setUserRoleId(null);
    setOpen(!open);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);
  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = dataFromApi.filter(
      ({ enName, arName, parentRoleEnName, parentRoleArName }: any) =>
        languageIsEn
          ? enName?.toLowerCase().includes(lowercasedValue) ||
            parentRoleEnName?.toLowerCase().includes(lowercasedValue)
          : arName?.includes(value) || parentRoleArName?.includes(value)
    );
    setRows(newRows);
  };
  return (
    <>
      {open && (
        <UserRoleModal
          open={open}
          handleClose={onClose}
          userRoleId={userRoleId}
          userRoles={dataFromApi}
        />
      )}
      <ListPageDX
        listTitle={t("User Roles")}
        name={t("User Role")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.organizationRoleId}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default UserRoles;
