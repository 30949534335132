import { useEffect, useState } from "react";
import AddEditModalDX from "../../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import CKEditorDX from "../../controls/ckeditordx";
import GridDX from "../../layout/griddx";
import useCorrespondenceActionService from "../../../shared/services/correspondenceactionservice";
import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";
import useDocumentTemplateService from "../../../shared/services/documenttemplateservice";
import Loading from "../../loading";

const CorrespondenceDeliveryForm = (props: any) => {
  const { t } = useTranslation();
  const { userData } = useAuthContext();
  const { setError, setInfo } = useNotificationContext();
  const { getDocumentTemplateByID } = useDocumentTemplateService();
  const { deliverCorrespondence } = useCorrespondenceActionService();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [content, setContent] = useState("");
  const [contentLang, setContentLang] = useState("en");

  useEffect(() => {
    setIsLoading(true);
    getDocumentTemplateByID(12)
      .then((res) => {
        setContent(res.content);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const contentChange = (newContent: string) => {
    setIsChanged(true);
    setContent(newContent);
  };

  const onSave = async () => {
    const data = {
      correspondenceId: props.correspondenceId,
      userId: userData.userId,
      deliveryNoteText: content,
    };
    setIsSaving(true);
    deliverCorrespondence(data)
      .then((res) => {
        setInfo(t("Correspondence marked as delivered"));
        props.handleClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  return (
    <AddEditModalDX
      open={props.open}
      handleClose={props.handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={t("Upload Delivery Form")}
      maxWidth="lg"
      onSaveClick={onSave}
    >
      <GridDX item xs={12} justifyContent="center">
        {isLoading && <Loading styles={{ height: "100%", width: "100%" }} />}
        <CKEditorDX
          label={t("Form")}
          langSelectorLabe={"Language"}
          contentLang={contentLang}
          setContentLang={setContentLang}
          data={content}
          onChange={contentChange}
        />
      </GridDX>
    </AddEditModalDX>
  );
};

export default CorrespondenceDeliveryForm;
