import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../components/business/listpagedx";
import { useNotificationContext } from "../../context/notificationcontext";
import {
  concatenateNameWithRole,
  DateSorter,
  DateTimeFormatter,
} from "../../shared/globals";
import { useAuthContext } from "../../context/authcontext";
import WorkflowModal from "../../components/editmodals/VMS/workflowmodal";
import useWorkflowService from "../../shared/services/vmsworkflowservice";

const Workflows = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const { getWorkflows, archiveWorkflow } = useWorkflowService();
  const [workflowId, setWorkflowId] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);

  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return (
          params.row.createdByUser &&
          concatenateNameWithRole(params.row.createdByUser)
        );
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Created at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getWorkflows()
      .then((workflows) => {
        console.log(workflows);
        const preprocessedData = workflows.map((workflow: any) => {
          return {
            ...workflow,
            createdDate: DateTimeFormatter(
              workflow.createdDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
          };
        });
        setDataFromApi(preprocessedData);
        setRows(preprocessedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveWorkflow(id)
      .then(() => {
        setInfo(t("Workflow deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (data: any) => {
    setWorkflowId(data.workflowId);
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setWorkflowId(null);
    setOpen(!open);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = dataFromApi.filter(
      ({ enName, arName, createdByUser }: any) =>
        languageIsEn
          ? enName?.toLowerCase().includes(lowercasedValue) ||
            createdByUser?.enFullName?.toLowerCase().includes(lowercasedValue)
          : arName?.includes(value) ||
            createdByUser?.arFullName?.includes(value)
    );
    setRows(newRows);
  };

  return (
    <>
      {open && (
        <WorkflowModal
          open={open}
          handleClose={onClose}
          workflowId={workflowId}
        />
      )}
      <ListPageDX
        listTitle={t("Workflow")}
        name={t("Workflow")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.workflowId}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default Workflows;
