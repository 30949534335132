import useSecureAPI from "../hooks/usesecureapi";

const useOrganizationRoleService = () => {
  const secureAPI = useSecureAPI();

  const getOrganizationRoles = async () => {
    const response = await secureAPI.get("OrganizationRole");
    return response.data;
  };

  const getOrganizationRoleById = async (id: number) => {
    const response = await secureAPI.get(`OrganizationRole/${id}`);
    return response.data;
  };
  const addOrganizationRole = async (data: any) => {
    const response = await secureAPI.post("OrganizationRole", data);
    return response.data;
  };

  const updateOrganizationRole = async (data: any) => {
    const response = await secureAPI.put(`OrganizationRole`, data);
    return response.data;
  };

  const deleteOrganizationRole = async (id: number) => {
    const response = await secureAPI.delete(`OrganizationRole/${id}`);
    return response.data;
  };

  return {
    getOrganizationRoles,
    getOrganizationRoleById,
    addOrganizationRole,
    updateOrganizationRole,
    deleteOrganizationRole
  };
};

export default useOrganizationRoleService;
