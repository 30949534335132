import useSecureAPI from "../hooks/usesecureapi";

const useCorrespondenceForwardService = () => {
  const secureAPI = useSecureAPI();

  const getForwardByCorrespondenceID = async (id: number) => {
    const response = await secureAPI.get(
      `CorrespondenceForward/bycorrespondence/${id}`
    );
    return response.data;
  };
  const getCorrespondenceForwardByID = async (id: number) => {
    const response = await secureAPI.get(`CorrespondenceForward/${id}`);
    return response.data;
  };

  const addCorrespondenceForward = async (data: any) => {
    const response = await secureAPI.post("CorrespondenceForward", data);
    return response.data;
  };

  const updateCorrespondenceForward = async (id: number, data: any) => {
    const response = await secureAPI.put(`CorrespondenceForward`, data);
    return response.data;
  };

  const deleteCorrespondenceForward = async (id: number) => {
    const response = await secureAPI.delete(`CorrespondenceForward/${id}`);
    return response.data;
  };

  return {
    getCorrespondenceForwardByID,
    getForwardByCorrespondenceID,
    addCorrespondenceForward,
    deleteCorrespondenceForward,
    updateCorrespondenceForward,
  };
};

export default useCorrespondenceForwardService;
