import Typography from "@mui/material/Typography";
import BoxDX from "../../../components/layout/boxdx";
import { DateFormatter } from "../../../shared/globals";
import { useAuthContext } from "../../../context/authcontext";
import TypeTranslator from "../../../shared/typetranslator";
import ButtonDX from "../../../components/controls/buttondx";
import { useTranslation } from "react-i18next";

const AppointmentCard = ({ data, handleExpand, expanded }: any) => {
  const { userData } = useAuthContext();
  const { t } = useTranslation();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;

  const { getAppointmentTypeValue } = TypeTranslator();
  return (
    <BoxDX
      sx={{
        p: 2,
        px: 3,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <BoxDX>
        <Typography variant="h6" sx={{ fontWeight: "medium" }}>
          {data.organizationName} |{" "}
          {getAppointmentTypeValue(data.appointmentTypeId)}
        </Typography>
        <Typography sx={{ color: "grey.600" }}>{data.visitPurpose}</Typography>
        <Typography sx={{ color: "grey.600" }}>
          <span style={{ fontWeight: "bold" }}>{data.visitLocation}</span> |{" "}
          <span style={{ fontWeight: "bold" }}>
            {DateFormatter(
              data.visitFromDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            )}{" "}
            -{" "}
            {DateFormatter(
              data.visitEndDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            )}
          </span>
        </Typography>
      </BoxDX>
      <ButtonDX onClick={handleExpand}>
        {!expanded ? t("Show Visitors") : t("Hide Visitors")}
      </ButtonDX>
    </BoxDX>
  );
};

export default AppointmentCard;
