import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNotificationContext } from "../context/notificationcontext";
import ListPageDX from "../components/business/listpagedx";
import UserModal from "../components/editmodals/usermodal";
import useUserService from "../shared/services/userservices";
import { DateTimeFormatter } from "../shared/globals";
import { useAuthContext } from "../context/authcontext";

const Users = () => {
  const { t, i18n } = useTranslation();
  const { setInfo, setError } = useNotificationContext();
  const { getUsers, deleteUser } = useUserService();
  const [userId, setUserId] = useState<any>(null);
  const [users, setUsers] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const languageIsEn = i18n.language === "en";

  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enFullName" : "arFullName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: languageIsEn ? "organizationRoleEnName" : "organizationRoleArName",
      headerName: `${t("Role")}`,
      flex: 1,
    },
    {
      field: "email",
      headerName: `${t("Email")}`,
      flex: 1,
    },
    {
      field: languageIsEn ? "enJobTitle" : "arJobTitle",
      headerName: `${t("Job Title")}`,
      flex: 1,
    },
    {
      field: languageIsEn ? "managerEnFullName" : "managerArFullName",
      headerName: `${t("Manager")}`,
      flex: 1,
    },
    {
      field: "isActive",
      headerName: `${t("Account Status")}`,
      flex: 1,
    },
    {
      field: "lastLoginDate",
      headerName: `${t("Last Login")}`,
      flex: 1,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getUsers() //fetch user data from APi
      .then((res) => {
        const preprocessedData = res.map((user: any) => {
          return {
            ...user,
            isActive: user.isActive ? t("Active") : t("Inactive"),
            lastLoginDate: DateTimeFormatter(
              user.lastLoginDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
          };
        });

        setRows(preprocessedData);
        setUsers(preprocessedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteUser(id)
      .then(() => {
        setInfo(t("User deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (user: any) => {
    setUserId(user.id);
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setUserId(null);
    setOpen(!open);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(users);
      return;
    }
    const newRows = users.filter(
      ({
        enFullName,
        arFullName,
        email,
        enJobTitle,
        arJobTitle,
        managerEnFullName,
        managerArFullName,
      }: any) =>
        languageIsEn
          ? enFullName?.toLowerCase().includes(value.toLowerCase()) ||
            enJobTitle?.toLowerCase().includes(value.toLowerCase()) ||
            email?.toLowerCase().includes(value.toLowerCase()) ||
            managerEnFullName?.toLowerCase().includes(value.toLowerCase())
          : arFullName?.includes(value) ||
            arJobTitle?.includes(value) ||
            email?.toLowerCase().includes(value.toLowerCase()) ||
            managerArFullName?.includes(value)
    );
    setRows(newRows);
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);
  return (
    <>
      {open && <UserModal open={open} handleClose={onClose} userId={userId} />}

      <ListPageDX
        listTitle={t("Users")}
        name={t("User")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.id}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default Users;
