import { useState } from "react";
import { useTranslation } from "react-i18next";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tab } from "@mui/material";
import { RouteOutlined } from "@mui/icons-material";
import BoxDX from "../../layout/boxdx";
import FileActivity from "./filetabcontent/fileactivity";
import FileShare from "./filetabcontent/fileshare";
import FileReminders from "./filetabcontent/filereminders";
import FileVersions from "./filetabcontent/fileversions";
import FileTransactions from "./filetabcontent/filetransactions";
import FileLinkedItems from "./filetabcontent/filelinkeditems";
import FolderReminders from "./directorytabcontent/folderreminders";
import FolderShare from "./directorytabcontent/foldershare";
import FolderActivity from "./directorytabcontent/folderactivity";

const DirectoryInformationTab = (props: any) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState("1");

  const handleTabChange = (event: any, newValue: any) => setTab(newValue);

  return (
    <BoxDX sx={{ typography: "body1" }}>
      <TabContext value={tab}>
        <BoxDX sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab
              icon={<AutorenewOutlinedIcon />}
              iconPosition="start"
              label={t("Activity")}
              value={"1"}
            />
            <Tab
              icon={<ShareOutlinedIcon />}
              iconPosition="start"
              label={t("Share")}
              value={"2"}
            />
            <Tab
              icon={<NotificationsActiveOutlinedIcon />}
              iconPosition="start"
              label={t("Reminders")}
              value={"3"}
            />
          </TabList>
        </BoxDX>
        <TabPanel value="1">
          <FolderActivity
            folderId={props.folderId}
            refetchStatusData={props.refetchActivity}
          />
        </TabPanel>
        <TabPanel value="2">
          <FolderShare folderId={props.folderId} />
        </TabPanel>
        <TabPanel value="3">
          <FolderReminders folderId={props.folderId} />
        </TabPanel>
        <TabPanel value="4">
          <FileLinkedItems />
        </TabPanel>
      </TabContext>
    </BoxDX>
  );
};

export default DirectoryInformationTab;
