import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../../components/business/listpagedx";
import { useNotificationContext } from "../../../context/notificationcontext";
import usePrivacyLevelsService from "../../../shared/services/privacylevelsservice";
import PrivacyLevelsModal from "../../../components/editmodals/privacylevelsmodal";
import {
  concatenateNameWithRole,
  DateSorter,
  DateTimeFormatter,
} from "../../../shared/globals";
import { useAuthContext } from "../../../context/authcontext";

const PrivacyLevels = () => {
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;

  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const { getPrivacyLevels, deletePrivacyLevel } = usePrivacyLevelsService();
  const [privacyLevelId, setPrivacyLevelId] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [privacyLevels, setPrivacyLevels] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Level Type")}`,
      flex: 1,
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return (
          params.row.createdByUser &&
          concatenateNameWithRole(params.row.createdByUser)
        );
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Created at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "modifiedDate",
      headerName: `${t("Last modified at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
  ];

  const getData = () => {
    setIsLoading(true); // commented out due to error from server side
    getPrivacyLevels() // api to fetch data types from the server
      .then((response) => {
        const preprocessedData = response.map((privacyLevel: any) => {
          return {
            ...privacyLevel,
            createdDate: DateTimeFormatter(
              privacyLevel.createdDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
            modifiedDate: DateTimeFormatter(
              privacyLevel.modifiedDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
          };
        });
        setPrivacyLevels(preprocessedData);
        setRows(preprocessedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deletePrivacyLevel(id)
      .then(() => {
        setInfo(t("Privacy level deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (privacyLevel: any) => {
    setPrivacyLevelId(privacyLevel.privacyLevelId);
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setPrivacyLevelId(null);
    setOpen(!open);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(privacyLevels);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = privacyLevels.filter(
      ({ enName, arName, createdByUser }: any) =>
        languageIsEn
          ? enName?.toLowerCase().includes(lowercasedValue) ||
            createdByUser?.enFullName?.toLowerCase().includes(lowercasedValue)
          : arName?.includes(value) ||
            createdByUser?.arFullName?.includes(value)
    );
    setRows(newRows);
  };
  return (
    <>
      {open && (
        <PrivacyLevelsModal
          open={open}
          handleClose={onClose}
          privacyLevelId={privacyLevelId}
        />
      )}
      <ListPageDX
        listTitle={t("Privacy Levels")}
        name={t("Privacy Level")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.privacyLevelId}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default PrivacyLevels;
