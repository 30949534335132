import { useEffect, useState } from "react";
import AddEditModalDX from "../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import { useNotificationContext } from "../../context/notificationcontext";
import Loading from "../loading";
import useOrganizationRoleService from "../../shared/services/userroleservice";
import SelectListDX from "../controls/selectlistdx";

const UserRoleModal = (props: any) => {
  const { userRoleId, open, handleClose, userRoles } = props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setError, setInfo } = useNotificationContext();
  const [userRolesDropDownData, setUserRolesDropDownData] = useState<any>([]);
  const {
    addOrganizationRole,
    updateOrganizationRole,
    getOrganizationRoleById,
  } = useOrganizationRoleService();

  const defaultValues = {
    organizationRoleId: 0,
    enName: "",
    arName: "",
    parentOrganizationRoleId: null,
  };

  const [userRoleData, setUserRoleData] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    // all roles dropdown options
    const dataForRolesDropDown = userRoles.map((res: any) => ({
      text: languageIsEn ? res.enName : res.arName,
      value: res.organizationRoleId,
    }));
    setUserRolesDropDownData(dataForRolesDropDown);
    if (userRoleId) {
      setIsLoading(true);
      getOrganizationRoleById(userRoleId)
        .then((role) => {
          if (userRoleId) {
            setUserRoleData(role);
          }
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (userRoleData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (userRoleData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);

    const operation = userRoleId
      ? updateOrganizationRole(userRoleData)
      : addOrganizationRole(userRoleData);

    operation
      .then(() => {
        setInfo(
          t(
            userRoleId
              ? "Role updated successfully"
              : "Role created successfully"
          )
        );
        handleClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setUserRoleData({
      ...userRoleData,
      [name]: value,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={userRoleId ? t("Edit Role") : t("Create a Role")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}

      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={userRoleData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={userRoleData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>

        <GridDX item xs={12}>
          <SelectListDX
            label={t("Select Parent Role")}
            items={userRolesDropDownData}
            name="parentOrganizationRoleId"
            onChange={handleInputChange}
            value={userRoleData.parentOrganizationRoleId}
            error={errors["parentOrganizationRoleId"]}
            InputLabelProps={{
              shrink: userRoleData.parentOrganizationRoleId !== null,
            }}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default UserRoleModal;
