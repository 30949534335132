import useSecureAPI from "../hooks/usesecureapi";

const useWorkflowService = () => {
  const secureAPI = useSecureAPI();

  const getWorkflows = async () => {
    const response = await secureAPI.get("vms/Workflow");
    return response.data;
  };

  const addWorkflow = async (data: any) => {
    const response = await secureAPI.post(`vms/Workflow`, data);
    return response.data;
  };

  const getWorkflowById = async (id: number) => {
    const response = await secureAPI.get(`vms/Workflow/${id}`);
    return response.data;
  };

  const updateWorkflow = async (id: number, data: any) => {
    const response = await secureAPI.put(`vms/Workflow`, data);
    return response.data;
  };

  const deleteWorkflow = async (id: number) => {
    const response = await secureAPI.delete(`vms/Workflow/${id}`);
    return response.data;
  };

  const archiveWorkflow = async (id: number) => {
    const response = await secureAPI.put(`vms/Workflow/archive/${id}`);
    return response.data;
  }

  return {
    getWorkflows,
    getWorkflowById,
    updateWorkflow,
    deleteWorkflow,
    addWorkflow,
    archiveWorkflow,
  };
};

export default useWorkflowService;
