import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../../../context/notificationcontext";
import TypeTranslator from "../../../../shared/typetranslator";
import AddEditModalDX from "../../../business/addeditmodaldx";
import SelectListDX from "../../../controls/selectlistdx";
import TextFieldDX from "../../../controls/textfielddx";
import GridDX from "../../../layout/griddx";
import Loading from "../../../loading";
import AutoCompleteListDX from "../../../controls/autocompletelistdx";
import {
  sortListAlphabetically,
  toPreviewDocument,
} from "../../../../shared/globals";
import { Typography } from "@mui/material";
import useVisitorService from "../../../../shared/services/vmsvisitorservice";
import { useAuthContext } from "../../../../context/authcontext";

const AddEditVisitorModal = (props: any) => {
  const {
    handleClose,
    open,
    appointmentData,
    setAppointmentData,
    visitorId,
    countries,
  } = props;
  const { t, i18n } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const { visitTypes, idTypes } = TypeTranslator();
  const { deleteVisitorDocument } = useVisitorService();
  const { userData } = useAuthContext();
  const defaultValues = {
    // using temp keyword to make visitor editable while in creation mode but we will pass 0 to the server for newly created visitor
    visitorId: `temp${Math.random() * 10000 + 1}`,
    appointmentId: 0,
    visitorTypeId: null,
    namePrefixId: 1,
    name: "",
    countryId: null,
    contactTypeId: 0,
    contactNumber: "",
    idTypeId: 1,
    idDetails: "",
    vehicleRegistrationNumber: "",
    vehicleColor: "",
    visitorDocuments: [
      {
        visitorDocumentId: 0,
        visitorId: 0,
        documentTypeId: 1,
        file: null,
        downloadURL: "",
      },
      {
        visitorDocumentId: 0,
        visitorId: 0,
        documentTypeId: 2,
        file: null,
        downloadURL: "",
      },
    ],
  };
  const [data, setData] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (visitorId || visitorId === 0) {
      const visitor = appointmentData.visitors.find(
        (v: any) => v.visitorId === visitorId
      );

      if (visitor.visitorDocuments.length === 0) {
        visitor.visitorDocuments = defaultValues.visitorDocuments;
      }
      if (visitor.visitorDocuments.length === 1) {
        // now check document type if its is 1 then add another document on 1 index
        // otherwise add on 0 index
        // also check if document type is 2 then place existing document on 1 index
        // and add new document on 0 index
        if (visitor.visitorDocuments[0].documentTypeId === 1) {
          visitor.visitorDocuments.push(defaultValues.visitorDocuments[1]);
        } else {
          // place existing document on 1 index and add new document on 0 index
          visitor.visitorDocuments.push(visitor.visitorDocuments[0]);
          visitor.visitorDocuments[0] = defaultValues.visitorDocuments[0];
        }
      }

      setData(visitor);
    } else {
      setData(defaultValues);
    }
  }, [visitorId]);

  const validateForm = () => {
    const newErrors: any = {};

    if (data.name.length === 0)
      newErrors["name"] = t("Visitor Name is required");

    if (!data.countryId) newErrors["countryId"] = t("Country is required");
    // idtype is required
    if (!data.idTypeId) newErrors["idTypeId"] = t("Id Type is required");
    // id details is required
    if (!data.idDetails) newErrors["idDetails"] = t("Id Details is required");
    // vehicle registration number is required
    if (!data.vehicleRegistrationNumber)
      newErrors["vehicleRegistrationNumber"] = t(
        "Vehicle Registration is required"
      );
    // vehicle color is required
    if (!data.vehicleColor)
      newErrors["vehicleColor"] = t("Vehicle Color is required");

    // documents are required

    if (
      !data.visitorDocuments[1].file &&
      !data.visitorDocuments[1].downloadURL
    ) {
      newErrors["visitorDocuments"] = t("Vehicle Registration is required");
      setError(t("Vehicle Registration is required"));
    }
    if (
      !data.visitorDocuments[0].file &&
      !data.visitorDocuments[0].downloadURL
    ) {
      newErrors["visitorDocuments"] = t("ID Card is required");
      setError(t("ID Card is required"));
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (!validateForm()) return;
    setIsLoading(true);
    if (visitorId) {
      const index = appointmentData.visitors.findIndex(
        (v: any) => v.visitorId === visitorId
      );
      let updatedVisitors = [...appointmentData.visitors];
      updatedVisitors[index] = data;
      setAppointmentData({
        ...appointmentData,
        visitors: updatedVisitors,
      });
      setInfo(t("Visitor updated successfully"));
    } else {
      setAppointmentData({
        ...appointmentData,
        visitors: [...appointmentData.visitors, data],
      });
      setInfo(t("Visitor added successfully"));
    }

    setIsLoading(false);
    handleClose();
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };
  const languageIsEn = i18n.language === "en";
  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={visitorId ? t("Edit Visitor") : t("Add Visitor")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Visitor Name")}
            name="name"
            value={data.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <SelectListDX
            label={`${t("Visitor Type")}`}
            items={visitTypes}
            name="visitorTypeId"
            value={data.visitorTypeId}
            onChange={handleInputChange}
            error={errors["visitorTypeId"]}
            InputLabelProps={{
              shrink: data.visitorTypeId !== null,
            }}
          />
        </GridDX>

        <GridDX item xs={4}>
          <SelectListDX
            label={`${t("Id Type")}`}
            items={idTypes}
            name="idTypeId"
            value={data.idTypeId}
            onChange={handleInputChange}
            error={errors["idTypeId"]}
            InputLabelProps={{
              shrink: data.idTypeId !== null,
            }}
          />
        </GridDX>
        <GridDX item xs={8}>
          <TextFieldDX
            label={data.idTypeId === 1 ? t("National ID") : t("Passport")}
            name="idDetails"
            value={data.idDetails}
            onChange={handleInputChange}
            error={errors["idDetails"]}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <TextFieldDX
            type="number"
            label={t("Mobile Number")}
            name="contactNumber"
            value={data.contactNumber}
            onChange={handleInputChange}
            error={errors["contactNumber"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <AutoCompleteListDX
            label={`${t("Nationality")}`}
            items={sortListAlphabetically(
              countries.map((x: any) => ({
                text: languageIsEn ? x.enName : x.enName,
                value: x.countryId,
              }))
            )}
            value={data.countryId}
            name="countryId"
            onChange={handleInputChange}
            error={errors["countryId"]}
            InputLabelProps={{
              shrink: data.countryId !== null,
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Vehicle Registration Number")}
            name="vehicleRegistrationNumber"
            value={data.vehicleRegistrationNumber}
            onChange={handleInputChange}
            error={errors["vehicleRegistrationNumber"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Vehicle Color")}
            name="vehicleColor"
            value={data.vehicleColor}
            onChange={handleInputChange}
            error={errors["vehicleColor"]}
          />
        </GridDX>

        <GridDX item xs={12}>
          <Typography variant="h6" component="label" sx={{ height: 32 }}>
            {t("Attach Documents")}
          </Typography>
        </GridDX>

        <GridDX item xs={4}>
          <Typography variant="body1" component="label" sx={{ height: 32 }}>
            {t("ID Card")}
          </Typography>
        </GridDX>
        {data.visitorDocuments[0]?.file ||
        data.visitorDocuments[0]?.downloadURL ? (
          <GridDX item xs={8} sx={{ display: "flex", gap: 10 }}>
            <Typography
              variant="body1"
              component="label"
              color={"primary"}
              sx={{ height: 32, cursor: "pointer" }}
              onClick={() => {
                if (data?.visitorDocuments[0]?.file) {
                  const url = URL.createObjectURL(
                    data?.visitorDocuments[0]?.file
                  );
                  url && window.open(url);
                } else if (data?.visitorDocuments[0]?.downloadURL) {
                  toPreviewDocument(
                    data?.visitorDocuments[0],
                    userData,
                    setError,
                    setIsLoading
                  );
                }
              }}
            >
              {t("View")}
            </Typography>
            <Typography
              variant="body1"
              component="label"
              color={"primary"}
              sx={{ height: 32, cursor: "pointer" }}
              onClick={() => {
                if (data.visitorDocuments[0].downloadURL) {
                  setIsLoading(true);
                  deleteVisitorDocument(
                    data.visitorDocuments[0].visitorDocumentId
                  )
                    .then(() => {
                      let updatedData = { ...data };
                      updatedData.visitorDocuments[0].downloadURL = "";
                      updatedData.visitorDocuments[0].file = null;
                      setData(updatedData);
                    })
                    .catch((error) => {
                      setError(error);
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                } else {
                  let updatedData = { ...data };
                  updatedData.visitorDocuments[0].file = null;
                  setData(updatedData);
                }
              }}
            >
              {t("Remove")}
            </Typography>
          </GridDX>
        ) : (
          <GridDX item xs={8}>
            <Typography
              variant="body1"
              component="label"
              color={"primary"}
              sx={{ height: 32, cursor: "pointer" }}
            >
              {t("Attach")}
              <input
                type="file"
                accept="image/*,application/pdf"
                hidden
                onChange={(event: any) => {
                  const file = event.target.files[0];
                  let updatedData = { ...data };
                  updatedData.visitorDocuments[0].file = file;
                  setData(updatedData);
                }}
              />
            </Typography>
          </GridDX>
        )}
        <GridDX item xs={4}>
          <Typography variant="body1" component="label" sx={{ height: 32 }}>
            {t("Vehicle Registration")}
          </Typography>
        </GridDX>
        {data.visitorDocuments[1]?.file ||
        data.visitorDocuments[1]?.downloadURL ? (
          <GridDX item xs={8} sx={{ display: "flex", gap: 10 }}>
            <Typography
              variant="body1"
              component="label"
              color={"primary"}
              sx={{ height: 32, cursor: "pointer" }}
              onClick={() => {
                if (data?.visitorDocuments[1]?.file) {
                  const url = URL.createObjectURL(
                    data?.visitorDocuments[1]?.file
                  );
                  url && window.open(url);
                } else if (data?.visitorDocuments[1]?.downloadURL) {
                  toPreviewDocument(
                    data?.visitorDocuments[1],
                    userData,
                    setError,
                    setIsLoading
                  );
                }
              }}
            >
              {t("View")}
            </Typography>

            <Typography
              variant="body1"
              component="label"
              color={"primary"}
              sx={{ height: 32, cursor: "pointer" }}
              onClick={() => {
                if (data.visitorDocuments[1].downloadURL) {
                  setIsLoading(true);
                  deleteVisitorDocument(
                    data.visitorDocuments[1].visitorDocumentId
                  )
                    .then(() => {
                      let updatedData = { ...data };
                      updatedData.visitorDocuments[1].downloadURL = "";
                      updatedData.visitorDocuments[1].file = null;
                      setData(updatedData);
                    })
                    .catch((error) => {
                      setError(error);
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                } else {
                  let updatedData = { ...data };
                  updatedData.visitorDocuments[1].file = null;
                  setData(updatedData);
                }
              }}
            >
              {t("Remove")}
            </Typography>
          </GridDX>
        ) : (
          <GridDX item xs={8}>
            <Typography
              variant="body1"
              component="label"
              color={"primary"}
              sx={{ height: 32, cursor: "pointer" }}
            >
              {t("Attach")}
              <input
                type="file"
                accept="image/*,application/pdf"
                hidden
                onChange={(event: any) => {
                  const file = event.target.files[0];
                  let updatedData = { ...data };
                  updatedData.visitorDocuments[1].file = file;
                  setData(updatedData);
                }}
              />
            </Typography>
          </GridDX>
        )}
      </GridDX>
    </AddEditModalDX>
  );
};

export default AddEditVisitorModal;
