import moment, { Moment } from "moment";
import momentHijri from "moment-hijri";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddEditModalDX from "../../business/addeditmodaldx";
import CheckBoxDX from "../../controls/checkboxdx";
import DateTimePickerDX from "../../controls/datetimepickerdx";
import SelectListDX from "../../controls/selectlistdx";
import GridDX from "../../layout/griddx";
import Loading from "../../loading";
import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";
import { API_DATETIME_FORMAT } from "../../../shared/globals";
import useCorrespondenceForwardService from "../../../shared/services/correspondenceforwardservice";
import useUserService from "../../../shared/services/userservices";
import TextFieldDX from "../../controls/textfielddx";

const CorrespondenceForwardModal = (props: any) => {
  const { open, handleClose, correspondenceId, correspondenceForwardId } =
    props;

  const { t, i18n } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const { userData } = useAuthContext();
  const { calendarId, userId } = userData;
  const languageIsEn = i18n.language === "en";
  const {
    addCorrespondenceForward,
    getCorrespondenceForwardByID,
    updateCorrespondenceForward,
  } = useCorrespondenceForwardService();
  const { getAccessibleUsers } = useUserService();

  interface ForwardData {
    correspondenceId: number;
    correspondenceForwardId: number;
    actionTypeId: number | null;
    userId: string | null | boolean;
    user: {} | string | null;
    correspondence: {} | string | null;
    notes: string | null;
    requireSignature: boolean;
    actionDeadline?: string | null | Moment;
  }

  const defaultValues: ForwardData = {
    correspondenceId: correspondenceId,
    correspondenceForwardId: 0,
    actionTypeId: null,
    userId: null,
    user: null,
    correspondence: null,
    notes: "",
    requireSignature: false,
    actionDeadline: null,
  };

  const actionTypeDropDownData = [
    { value: 1, text: t("For Information") },
    { value: 2, text: t("For Approval") },
  ];

  const [Forward, setForward] = useState<ForwardData>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  const [usersDropDownData, setUsersDropDownData] = useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    const p1 = getAccessibleUsers();
    const p2 = correspondenceForwardId
      ? getCorrespondenceForwardByID(correspondenceForwardId)
      : null;
    Promise.all([p1, p2])
      .then(([users, forward]) => {
        // all users dropdown options
        const filteredUsers = users?.filter((res: any) => userId !== res.id);
        const dataForUsersDropDown = filteredUsers.map((res: any) => ({
          text: `${languageIsEn ? res.enFullName : res.arFullName}   
          ${
            res.organizationRoleEnName != null &&
            res.organizationRoleArName != null
              ? ` - ${
                  languageIsEn
                    ? res.organizationRoleEnName
                    : res.organizationRoleArName
                }`
              : ""
          }`,
          value: res.id,
        }));
        setUsersDropDownData(dataForUsersDropDown);

        if (correspondenceForwardId) {
          setForward({ ...forward, user: null });
        }
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const validateForm = () => {
    const newErrors: any = {};
    if (Forward.actionTypeId == null)
      newErrors[`actionTypeId`] = t("Forward type is required");
    if (Forward.actionDeadline == null)
      newErrors[`actionDeadline`] = t("Action deadline is required");
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const onSave = async () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    const operation = correspondenceForwardId
      ? updateCorrespondenceForward(correspondenceForwardId, {
          ...Forward,
          actionDeadline: moment(Forward.actionDeadline).format(
            API_DATETIME_FORMAT
          ),
        })
      : addCorrespondenceForward(Forward);
    operation
      .then(() => {
        setInfo(
          t(
            correspondenceForwardId
              ? "Data updated successfully"
              : "Correspondence forwarded successfully"
          )
        );
        handleClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    const updatedForward = {
      ...Forward,
      [name]: type === "checkbox" ? checked : value,
    };
    setForward(updatedForward);
  };

  const handleDateTimeChange = (e: any) => {
    setIsChanged(true);
    setForward({
      ...Forward,
      actionDeadline: moment(e),
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={
        correspondenceForwardId
          ? t("Edit Correspondence Forward")
          : t("Forward Correspondence")
      }
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <SelectListDX
            label={t("Select Receiving User")}
            items={usersDropDownData}
            name="userId"
            value={Forward.userId}
            onChange={(e: any) => handleInputChange(e)}
            error={errors[`userId`]}
            disabled={correspondenceForwardId}
            InputLabelProps={{
              shrink: Forward.userId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>

        <GridDX item xs={12}>
          <SelectListDX
            label={t("Select Action Type")}
            items={actionTypeDropDownData}
            name="actionTypeId"
            value={Forward.actionTypeId}
            onChange={(e: any) => handleInputChange(e)}
            error={errors[`actionTypeId`]}
            InputLabelProps={{
              shrink: Forward.actionTypeId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>

        <GridDX
          item
          xs={12}
          sx={{
            "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
              width: "100% !important",
            },
            "& .css-1rutzcu-MuiFormControl-root-MuiTextField-root": {
              width: "100% !important",
            },
          }}
        >
          <DateTimePickerDX
            label={t("Action Deadline")}
            name="actionDeadline"
            value={Forward.actionDeadline}
            onChange={handleDateTimeChange}
            error={errors["actionDeadline"]}
            minDate={calendarId == 1 ? moment() : momentHijri()}
          />
        </GridDX>

        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Notes")}
            name="notes"
            multiline
            rows={2}
            value={Forward.notes}
            onChange={handleInputChange}
          />
        </GridDX>

        {Forward.actionTypeId && Forward.actionTypeId == 2 && (
          <GridDX item xs={12}>
            <CheckBoxDX
              name="requireSignature"
              label={t("Signature is required")}
              checked={Forward.requireSignature}
              onChange={(e: any) => handleInputChange(e)}
            />
          </GridDX>
        )}
      </GridDX>
    </AddEditModalDX>
  );
};

export default CorrespondenceForwardModal;
