import useSecureAPI from "../hooks/usesecureapi";

const useVisitorService = () => {
  const secureAPI = useSecureAPI();


  const deleteVisitor = async (id: number) => {
    const response = await secureAPI.delete(`vms/Visitor/${id}`);
    return response.data;
  };

  const deleteVisitorDocument = async (id: number) => {
    const response = await secureAPI.delete(`vms/VisitorDocument/${id}`);
    return response.data;
  };

  return {
    deleteVisitor,
    deleteVisitorDocument
  };
};

export default useVisitorService;