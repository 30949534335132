import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import ListPageDX from "../../../components/business/listpagedx";
import EntityTypeModal from "../../../components/editmodals/entitytypemodal";

import useEntityTypeService from "../../../shared/services/entitytypeservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import { useAuthContext } from "../../../context/authcontext";
import {
  concatenateNameWithRole,
  DateSorter,
  DateTimeFormatter,
} from "../../../shared/globals";

const EntityTypes = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const { getEntityTypes, deleteType } = useEntityTypeService();
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const columns: GridColDef[] = [
    {
      field: i18n.language === "en" ? "enName" : "arName",
      headerName: `${t("Type")}`,
      flex: 1,
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,

      valueGetter: (params: any) => {
        return (
          params.row.createdByUser &&
          concatenateNameWithRole(params.row.createdByUser)
        );
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Created at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "modifiedDate",
      headerName: `${t("Last modified at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
  ];

  const [entityTypeId, setEntityTypeId] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [entityTypes, setEntityTypes] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getData();
  }, [i18n.language]);

  const getData = () => {
    setIsLoading(true);
    getEntityTypes() // api to fetch data types from the server
      .then((response) => {
        const preprocessedData = response.map((entityType: any) => {
          return {
            ...entityType,
            createdDate: DateTimeFormatter(
              entityType.createdDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
            modifiedDate: DateTimeFormatter(
              entityType.modifiedDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
          };
        });
        setEntityTypes(preprocessedData);
        setRows(preprocessedData);
      })

      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteType(id)
      .then(() => {
        setInfo(t("Entity type deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (type: any) => {
    setEntityTypeId(type.entityTypeId);
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setEntityTypeId(null);
    setOpen(!open);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(entityTypes);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = entityTypes.filter(
      ({ enName, arName, createdByUser }: any) =>
        languageIsEn
          ? enName?.toLowerCase().includes(lowercasedValue) ||
            createdByUser?.enFullName?.toLowerCase().includes(lowercasedValue)
          : arName?.includes(value) ||
            createdByUser?.arFullName?.includes(value)
    );
    setRows(newRows);
  };

  return (
    <>
      {open && (
        <EntityTypeModal
          open={open}
          handleClose={onClose}
          entityTypeId={entityTypeId}
        />
      )}
      <ListPageDX
        listTitle={t("Entity Types")}
        name={t("Entity Type")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.entityTypeId}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default EntityTypes;
