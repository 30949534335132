import { useState } from "react";
import { useTranslation } from "react-i18next";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tab } from "@mui/material";
import { ForwardToInboxOutlined, RouteOutlined } from "@mui/icons-material";

import CorrespondenceAttachments from "./tabcontent/correspondenceattachments";
import CorrespondenceFollowers from "./tabcontent/correspondencefollowers";
import CorrespondenceReminders from "./tabcontent/correspondencereminders";
import StatusTimeline from "./tabcontent/correspondencestatuses";
import CorrespondenceRoutes from "./tabcontent/correspondenceroutes";

import BoxDX from "../../../components/layout/boxdx";
import CorrespondenceForward from "./tabcontent/correspondenceforward";

const CorrespondenceTab = (props: any) => {
  const { status, refetchStatusData } = props;
  const { t, i18n } = useTranslation();
  const [tab, setTab] = useState("1");

  const handleTabChange = (event: any, newValue: any) => setTab(newValue);

  return (
    <BoxDX sx={{ typography: "body1" }}>
      <TabContext value={tab}>
        <BoxDX sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab
              icon={<AutorenewOutlinedIcon />}
              iconPosition="start"
              label={t("Statuses")}
              value={"1"}
            />
            <Tab
              icon={<AttachFileOutlinedIcon />}
              iconPosition="start"
              label={t("Attachments")}
              value={"2"}
            />
            <Tab
              icon={<VisibilityOutlinedIcon />}
              iconPosition="start"
              label={t("Followers")}
              value={"3"}
            />
            <Tab
              icon={<NotificationsActiveOutlinedIcon />}
              iconPosition="start"
              label={t("Reminders")}
              value={"4"}
            />
            <Tab
              icon={<RouteOutlined />}
              iconPosition="start"
              label={t("Routing")}
              value={"5"}
            />
            <Tab
              icon={<ForwardToInboxOutlined />}
              iconPosition="start"
              label={t("CC/Forward")}
              value={"6"}
            />
          </TabList>
        </BoxDX>
        <TabPanel value="1">
          <StatusTimeline
            correspondenceId={props.correspondenceId}
            refetchStatusData={refetchStatusData}
          />
        </TabPanel>
        <TabPanel value="2">
          <CorrespondenceAttachments
            getDetails={props.getDetails}
            correspondenceId={props.correspondenceId}
            applyWatermark={props.applyWatermark}
            status={status}
          />
        </TabPanel>
        <TabPanel value="3">
          <CorrespondenceFollowers
            correspondenceId={props.correspondenceId}
            status={status}
          />
        </TabPanel>
        <TabPanel value="4">
          <CorrespondenceReminders
            correspondenceId={props.correspondenceId}
            status={status}
          />
        </TabPanel>
        <TabPanel value="5">
          <CorrespondenceRoutes
            correspondenceId={props.correspondenceId}
            hasWorkflow={props.hasWorkflow}
            status={status}
          />
        </TabPanel>
        <TabPanel value="6">
          <CorrespondenceForward
            correspondenceId={props.correspondenceId}
            status={status}
          />
        </TabPanel>
      </TabContext>
    </BoxDX>
  );
};

export default CorrespondenceTab;
