import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import GridDX from "../../../components/layout/griddx";
import LoadingButtonDX from "../../../components/controls/loadingbuttondx";
import { useNotificationContext } from "../../../context/notificationcontext";
import SearchBoxDX from "../../../components/controls/searchboxdx";
import CheckinsListingAccordion from "./checkinslistingaccordion";
import useCheckinService from "../../../shared/services/vmscheckinservice";
import useCountryService from "../../../shared/services/countryservice";
import Loading from "../../../components/loading";
import useAppointmentService from "../../../shared/services/vmsappointmentservice";

const Checkins = () => {
  const { t, i18n } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const [isLoading, setIsLoading] = useState(false);
  const { getCheckinData } = useCheckinService();
  const { getAppointmentForToday } = useAppointmentService();
  const [appointments, setAppointments] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const validateForm = () => {
    if (searchValue.length === 0) {
      setError(t("Please enter a search value."));
      return false;
    }
    return true;
  };
  const [countries, setCountries] = useState<any[]>([]);
  const { getCountries } = useCountryService();
  useEffect(() => {
    setIsLoading(true);
    const p1 = getCountries();
    const p2 = getAppointmentForToday();
    Promise.all([p1, p2])
      .then(([countries, appointments]) => {
        setCountries(countries);
        setAppointments(appointments);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getData = () => {
    if (!validateForm()) return;
    setIsLoading(true);
    getCheckinData(searchValue)
      .then((appointments) => {
        if (appointments.length === 0) {
          setError(t("No records found"));
          setAppointments([]);
        }
        setAppointments(appointments);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <GridDX container flexDirection="column" rowSpacing={2}>
      <GridDX item xs={4}>
        <Typography
          variant="h4"
          component="div"
          sx={{ flexGrow: 1, fontWeight: "bold", py: 2 }}
        >
          {t("Check Ins")}
        </Typography>
      </GridDX>
      <GridDX
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
      >
        <SearchBoxDX
          sx={{
            backgroundColor: "#F2F2F2",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
            "& .MuiOutlinedInput-input": {
              padding: "8px",
            },
          }}
          placeholder={t("Search")}
          value={searchValue}
          onChange={(e: any) => setSearchValue(e.target.value)}
          onKeyPress={(e: any) => {
            if (e.key === "Enter") {
              getData();
            }
          }}
        />
        <LoadingButtonDX onClick={getData}> {t("Search")}</LoadingButtonDX>
      </GridDX>
      {isLoading ? (
        <Loading styles={{ height: "100%", width: "100%" }} />
      ) : (
        appointments.map((e, i) => {
          return (
            <GridDX key={i} item xs={12}>
              <CheckinsListingAccordion
                e={e}
                setAppointments={setAppointments}
                countries={countries}
              />
            </GridDX>
          );
        })
      )}
    </GridDX>
  );
};

export default Checkins;
