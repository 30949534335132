import useSecureAPI from "../hooks/usesecureapi";

const useCheckinService = () => {
  const secureAPI = useSecureAPI();

  const getCheckinData = async (search_str: any) => {
    // we have to url encode the search string
    const encodedStr = encodeURIComponent(search_str);
    const response = await secureAPI.get(`vms/Appointment/checkinsearch/${encodedStr}`);
    return response.data;
  };

  const checkinVisitor = async (id: any) => {
    const response = await secureAPI.put(`vms/Visitor/checkin/${id}`);
    return response.data;
  }
  const checkoutVisitor = async (id: any) => {
    const response = await secureAPI.put(`vms/Visitor/checkout/${id}`);
    return response.data;
  }




  return {
    getCheckinData,
    checkinVisitor,
    checkoutVisitor


  };
};

export default useCheckinService;