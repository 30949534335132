import { useEffect, useState } from "react";
import AddEditModalDX from "../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import { useNotificationContext } from "../../context/notificationcontext";
import useEntityService from "../../shared/services/entityservice";
import SelectListDX from "../controls/selectlistdx";
import useEntityTypeService from "../../shared/services/entitytypeservice";
import Loading from "../loading";
import { RegularExpressions } from "../../shared/globals";
import useUserService from "../../shared/services/userservices";
import AutoCompleteMultiple from "../controls/autocompletemultiple";
import AutoCompleteListDX from "../controls/autocompletelistdx";
import useDomainTypeService from "../../shared/services/domaintypeservice";

const EntityModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { open, handleClose, entityId } = props;

  const { setError, setInfo } = useNotificationContext();
  const { addEntity, updateEntity, getEntityById, getEntity } =
    useEntityService();
  const { getEntityTypes } = useEntityTypeService();
  const { getActiveUsers } = useUserService();
  const { getDomainTypes } = useDomainTypeService();

  const defaultValues = {
    entityId: 0,
    enName: "",
    arName: "",
    email: "",
    fax: "",
    phone: "",
    entityTypeId: null,
    parentEntityId: null,
    entityType: null,
    domainTypeId: null,
    userIds: [],
  };

  const [entityData, setEntityData] = useState(defaultValues);
  const [entityTypes, setEntityTypes] = useState<any>([]);
  const [entities, setEntities] = useState<any>([]);
  const [usersDropDownData, setUsersDropDownData] = useState<any>([]);
  const [domainTypes, setDomainTypes] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setIsLoading(true);
    const p1 = getEntityTypes();
    const p2 = entityId ? getEntityById(entityId) : null;
    const p3 = getEntity();
    const p4 = getActiveUsers();
    const p5 = getDomainTypes();
    Promise.all([p1, p2, p3, p4, p5])
      .then(([entityTypes, entity, entities, users, domainTypes]) => {
        const formatedEntityTypesForDropDown = entityTypes.map((res: any) => ({
          text: languageIsEn ? res.enName : res.arName,
          value: res.entityTypeId,
        }));
        setEntityTypes(formatedEntityTypesForDropDown);

        if (entityId) {
          const { entityAssignees, ...rest } = entity;
          if (entityAssignees && entityAssignees.length > 0) {
            const userIds = entityAssignees
              .map((assignee: any) => {
                const user = users.find(
                  (user: any) => user.id === assignee.userId
                );
                if (user) {
                  const fullName = languageIsEn
                    ? user.enFullName
                    : user.arFullName;
                  return { text: fullName, value: user.id };
                }
                return null; // Handle cases where a user is not found
              })
              .filter(Boolean); // Remove null entries if any
            setEntityData({ ...rest, userIds });
          } else {
            setEntityData({ ...rest, userIds: [] });
          }
        }

        const formatedEntityForDropDown = entities.map(
          (res: any) =>
            entityId != res.entityId && {
              text: languageIsEn ? res.enName : res.arName,
              value: res.entityId,
            }
        );
        setEntities(formatedEntityForDropDown);

        // all users dropdown options
        const dataForUsersDropDown = users.map((res: any) => ({
          text: languageIsEn ? res.enFullName : res.arFullName,
          value: res.id,
        }));
        setUsersDropDownData(dataForUsersDropDown);

        // domain types dropdown options
        const formatedDomainTypes = domainTypes.map((res: any) => ({
          text: languageIsEn ? res.enName : res.arName,
          value: res.domainTypeId,
        }));
        setDomainTypes(formatedDomainTypes);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (entityData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (entityData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");
    if (entityData.email.length === 0)
      newErrors["email"] = t("Email is required");
    else if (!RegularExpressions.emailAddress.test(entityData.email))
      newErrors["email"] = t("Invalid email address");
    if (entityData.entityTypeId === null)
      newErrors["entityTypeId"] = t("Entity type is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      const { userIds } = entityData;
      const users = userIds.map((e: any) => e.value);
      setIsLoading(true);
      entityId
        ? updateEntity(entityId, {
            ...entityData,
            entityType: null,
            userIds: users,
          })
            .then(() => {
              setInfo(t("Entity updated successfully"));
              handleClose(true);
            })
            .catch((err) => setError(err))
            .finally(() => setIsLoading(false))
        : addEntity({ ...entityData, userIds: users })
            .then(() => {
              setInfo(t("Entity created successfully"));
              handleClose(true);
            })
            .catch((err) => setError(err))
            .finally(() => setIsLoading(false));
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setEntityData({
      ...entityData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={entityId ? t("Edit Entity") : t("Create Entity")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={entityData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={entityData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Email")}
            name="email"
            value={entityData.email}
            onChange={handleInputChange}
            error={errors["email"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Fax")}
            name="fax"
            value={entityData.fax}
            onChange={handleInputChange}
            type="number"
            error={errors["fax"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Phone")}
            name="phone"
            value={entityData.phone}
            onChange={handleInputChange}
            type="number"
            error={errors["phone"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={`${t("Select Entity Type")}`}
            items={entityTypes}
            name="entityTypeId"
            value={entityData.entityTypeId}
            onChange={handleInputChange}
            error={errors["entityTypeId"]}
            InputLabelProps={{
              shrink: entityData.entityTypeId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={`${t("Select Domain Type")}`}
            items={domainTypes}
            name="domainTypeId"
            value={entityData.domainTypeId}
            onChange={handleInputChange}
            error={errors["domainTypeId"]}
            InputLabelProps={{
              shrink: entityData.domainTypeId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        <GridDX item xs={12}>
          <AutoCompleteListDX
            items={entities}
            name="parentEntityId"
            label={t("Select Parent Entity")}
            onChange={handleInputChange}
            value={entityData.parentEntityId}
            error={errors["parentEntityId"]}
            InputLabelProps={{
              shrink: entityData.parentEntityId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>

        <GridDX item xs={12}>
          <AutoCompleteMultiple
            sx={{ width: "100%" }}
            label={t("Assignees")}
            name="userIds"
            placeholder={t("Assignee")}
            value={entityData.userIds}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setEntityData({
                ...entityData,
                userIds: newValue,
              });
            }}
            error={errors["userIds"]}
            list={usersDropDownData}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default EntityModal;
