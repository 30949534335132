import {
  ArrowCircleUpOutlined,
  ArrowCircleDownOutlined,
  Delete,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../../context/notificationcontext";
import {
  handleInputChange,
  sortListAlphabetically,
} from "../../../shared/globals";
import useEntityService from "../../../shared/services/entityservice";
import useUserService from "../../../shared/services/userservices";
import AddEditModalDX from "../../business/addeditmodaldx";
import AutoCompleteListDX from "../../controls/autocompletelistdx";
import ButtonDX from "../../controls/buttondx";
import CheckBoxDX from "../../controls/checkboxdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../loading";
import useWorkflowService from "../../../shared/services/vmsworkflowservice";

const WorkflowModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { open, handleClose, workflowId } = props;
  const { setError, setInfo } = useNotificationContext();
  const { getWorkflowById, addWorkflow, updateWorkflow } = useWorkflowService();
  const { getEntity } = useEntityService();
  const { getActiveUsers } = useUserService();
  const defaultValues = {
    workflowId: 0,
    enName: "",
    arName: "",
    workflowSteps: [],
  };

  const [workflowData, setWorkflowData] = useState(defaultValues);
  interface WorkflowStepDefaultValueInterface {
    workflowStepId: number;
    workflowId: number;
    sequenceNumber: number;
    userId: string | null;
    actionTypeId: number | null;
  }
  const workflowStepDefaultValue: WorkflowStepDefaultValueInterface = {
    workflowStepId: 0,
    workflowId: 0,
    sequenceNumber: 0,
    userId: null,
    actionTypeId: null,
  };

  const actionTypeDropDownData = [
    { value: 1, text: t("For Information") },
    { value: 2, text: t("For Approval") },
  ];
  const [workflowSteps, setWorkflowSteps] = useState([
    workflowStepDefaultValue,
  ]);

  const [usersDropDownData, setUsersDropDownData] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setIsLoading(true);
    const p1 = getActiveUsers();
    const p2 = workflowId ? getWorkflowById(workflowId) : null;
    Promise.all([p1, p2])
      .then(([users, workflow]) => {
        console.log(users);
        setUsersDropDownData(users);
        if (workflowId) {
          setWorkflowData(workflow);
          // sort the workflow steps by sequence number
          setWorkflowSteps(
            workflow?.workflowSteps.sort(
              (a: any, b: any) => a.sequenceNumber - b.sequenceNumber
            )
          );
        }
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (workflowData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (workflowData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");

    workflowSteps.map((workflowStep, index) => {
      console.log(workflowStep);
      if (workflowStep.userId == null)
        newErrors[`userId${index}`] = t("Receiving user is required");
      if (workflowStep.actionTypeId == null)
        newErrors[`actionTypeId${index}`] = t("Workflow step type is required");
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (!validateForm()) {
      return;
    }
    const workflowStepsWithSequence = workflowSteps.map(
      (workflowStep: any, index: number) => {
        return { ...workflowStep, sequenceNumber: index + 1 };
      }
    );
    setIsLoading(true);
    const operation = workflowId
      ? updateWorkflow(workflowId, {
          ...workflowData,
          workflowSteps: workflowStepsWithSequence,
        })
      : addWorkflow({
          ...workflowData,
          workflowSteps: workflowStepsWithSequence,
        });
    operation
      .then(() => {
        setInfo(
          t(
            workflowId
              ? "Workflow updated successfully"
              : "Workflow created successfully"
          )
        );
        handleClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const handleWorkflowStepInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value, checked, type } = e.target;
    const updatedworkflowSteps = [...workflowSteps];

    updatedworkflowSteps[index] = {
      ...updatedworkflowSteps[index],
      [name]: type === "checkbox" ? checked : value,
    };
    setWorkflowSteps(updatedworkflowSteps);
  };

  const handleAddTemplate = () => {
    setWorkflowSteps((prev) => [...prev, workflowStepDefaultValue]);
  };

  const handleRemoveTemplate = (index: number) => {
    // Create a new array with the item removed
    const updatedworkflowSteps = [...workflowSteps];
    updatedworkflowSteps.splice(index, 1);

    // Set the updated array using setWorkflowSteps
    setWorkflowSteps(updatedworkflowSteps);
  };

  const handleClickUpward = (index: number) => {
    setWorkflowSteps((prev) => {
      const current = [...prev];
      const temp = current[index];
      current[index] = current[index - 1];
      current[index - 1] = temp;
      return current;
    });
  };

  const handleClickDownward = (index: number) => {
    setWorkflowSteps((prev) => {
      const current = [...prev];
      const temp = current[index];
      current[index] = current[index + 1];
      current[index + 1] = temp;
      return current;
    });
  };

  const ButtonWrapper = (props: any) => {
    const { disabled, onClick } = props;
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        style={{
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
          color: disabled ? "#ccc" : "inherit",
        }}
      >
        {props.children}
      </button>
    );
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={workflowId ? t("Edit Workflow") : t("Create Workflow")}
      maxWidth="lg"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={6}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={workflowData.enName}
            onChange={(event: any) =>
              handleInputChange({
                event: event,
                setIsChanged: setIsChanged,
                setData: setWorkflowData,
                data: workflowData,
              })
            }
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={6}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={workflowData.arName}
            onChange={(event: any) =>
              handleInputChange({
                event: event,
                setIsChanged: setIsChanged,
                setData: setWorkflowData,
                data: workflowData,
              })
            }
            error={errors["arName"]}
          />
        </GridDX>

        {!(workflowId && isLoading) && (
          <GridDX
            container
            sx={{
              py: "20px",
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                fontWeight: "bold",
                textAlign: "center",
                margin: "10px 0",
                width: "100%",
              }}
            >
              {t("WORKFLOW STEPS")}
            </Typography>
            <GridDX item xs={6} sx={{ position: "absolute", right: 0 }}>
              <ButtonDX onClick={handleAddTemplate}>
                {t("Add Workflow Step")}
              </ButtonDX>
            </GridDX>
          </GridDX>
        )}

        {!(workflowId && isLoading) &&
          workflowSteps.map((workflow, index) => {
            return (
              <GridDX
                container
                columnSpacing={1}
                rowSpacing={2}
                sx={{ pt: 1, pb: 1 }}
                key={index}
              >
                <GridDX
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography textAlign={"center"}>
                    {`${index + 1} > `}
                  </Typography>
                </GridDX>

                <GridDX item xs={3}>
                  <AutoCompleteListDX
                    label={t("Select Receiving User")}
                    items={sortListAlphabetically(
                      usersDropDownData.map((user: any) => ({
                        text: languageIsEn ? user.enFullName : user.arFullName,
                        value: user.id,
                      }))
                    )}
                    name="userId"
                    value={workflow.userId}
                    onChange={(e: any) =>
                      handleWorkflowStepInputChange(e, index)
                    }
                    error={errors[`userId${index}`]}
                    InputLabelProps={{
                      shrink: workflow.userId !== null, // Set to true if there's a value just to handle label position
                    }}
                  />
                </GridDX>

                <GridDX item xs={4}>
                  <AutoCompleteListDX
                    label={t("Select Workflow Step Type")}
                    items={sortListAlphabetically(actionTypeDropDownData)}
                    name="actionTypeId"
                    value={workflow.actionTypeId}
                    onChange={(e: any) =>
                      handleWorkflowStepInputChange(e, index)
                    }
                    error={errors[`actionTypeId${index}`]}
                    InputLabelProps={{
                      shrink: workflow.actionTypeId !== null, // Set to true if there's a value just to handle label position
                    }}
                  />
                </GridDX>

                <GridDX
                  item
                  xs={1}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    color: "primary.main",
                  }}
                >
                  <ButtonWrapper
                    onClick={() => handleClickUpward(index)}
                    disabled={index === 0}
                  >
                    <ArrowCircleUpOutlined />
                  </ButtonWrapper>
                  <ButtonWrapper
                    disabled={index === workflowSteps.length - 1}
                    onClick={() => handleClickDownward(index)}
                  >
                    <ArrowCircleDownOutlined />
                  </ButtonWrapper>
                </GridDX>

                <GridDX
                  item
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    color: "error.main",
                  }}
                >
                  <ButtonWrapper
                    disabled={false}
                    onClick={() => handleRemoveTemplate(index)}
                  >
                    <Delete />
                  </ButtonWrapper>
                </GridDX>
              </GridDX>
            );
          })}
      </GridDX>
    </AddEditModalDX>
  );
};

export default WorkflowModal;
