import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";

import { useNotificationContext } from "../../../../context/notificationcontext";
import { useAuthContext } from "../../../../context/authcontext";

import ListPageDX from "../../../../components/business/listpagedx";

import {
  CorrespondenceStatus,
  DateTimeFormatter,
  DateSorter,
  concatenateNameWithRole,
} from "../../../../shared/globals";
import useCorrespondenceForwardService from "../../../../shared/services/correspondenceforwardservice";
import CorrespondenceForwardModal from "../../../../components/editmodals/correspondence/correspondenceforwardmodal";

const CorrespondenceForward = (props: any) => {
  const { status } = props;

  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { t, i18n } = useTranslation();
  const { correspondenceId } = props;
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();

  const { getForwardByCorrespondenceID, deleteCorrespondenceForward } =
    useCorrespondenceForwardService();

  const [rows, setRows] = useState<any>([]);
  const [correspondenceForwardId, setCorrespondenceForwardId] =
    useState<any>(null);
  const [Forwards, setForwards] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "user",
      headerName: `${t("Receiving User")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return (
          params.row.user &&
          (languageIsEn
            ? params.row.user.enFullName
            : params.row.user.arFullName)
        );
      },
    },
    {
      field: "actionTypeId",
      headerName: `${t("Action Type")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.actionTypeId === 1
          ? t("For Information")
          : t("For Approval");
      },
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,

      valueGetter: (params: any) => {
        return (
          params.row.createdByUser &&
          concatenateNameWithRole(params.row.createdByUser)
        );
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Date Added")}`,
      flex: 1,
      valueGetter(params) {
        return DateTimeFormatter(
          params.row.createdDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
      sortComparator: DateSorter,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getForwardByCorrespondenceID(correspondenceId)
      .then((response) => {
        const data = response.filter(
          (item: any) => item.createdByUser.id === userData.userId
        );
        setRows(data);
        setForwards(data);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteCorrespondenceForward(id)
      .then(() => {
        setInfo(t("Correspondence Forward deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (correspondenceForwardData: any) => {
    setCorrespondenceForwardId(
      correspondenceForwardData.correspondenceForwardId
    );
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setOpen(!open);
    setCorrespondenceForwardId(null);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(Forwards);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = Forwards.filter(
      ({ user, createdByUser, actionTypeId }: any) =>
        languageIsEn
          ? user?.enFullName.toLowerCase().includes(lowercasedValue) ||
            createdByUser.enFullName.toLowerCase().includes(lowercasedValue) ||
            (actionTypeId === 1 ? t("For Information") : t("For Approval"))
              .toLowerCase()
              .includes(lowercasedValue)
          : user?.arFullName.includes(value) ||
            createdByUser.arFullName.includes(value) ||
            (actionTypeId === 1
              ? t("For Information")
              : t("For Approval")
            ).includes(value)
    );
    setRows(newRows);
  };
  return (
    <>
      {open && (
        <CorrespondenceForwardModal
          open={open}
          handleClose={onClose}
          correspondenceId={correspondenceId}
          correspondenceForwardId={correspondenceForwardId}
        />
      )}
      <ListPageDX
        listTitle={t("Correspondence Forwards")}
        name={t("Forward")}
        createLabel={t("Forward")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.correspondenceForwardId}
        isLoading={isLoading}
        toCreate={status === CorrespondenceStatus.Pending ? toCreate : null}
        onDelete={status === CorrespondenceStatus.Pending ? onDelete : null}
        onEdit={status === CorrespondenceStatus.Pending ? onEdit : null}
        setGridFilterCriteria={setGridFilterCriteria}
        exportToPDF={false}
        exportToCSV={false}
      />
    </>
  );
};

export default CorrespondenceForward;
