import { useNavigate, useParams } from "react-router-dom";

import UserGroupModal from "../components/editmodals/usergroupmodal";

const UserGroupDetails = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const onClose = () => {
    navigate("/cts/dashboard");
  };

  return <UserGroupModal open={true} handleClose={onClose} groupId={id} />;
};

export default UserGroupDetails;
