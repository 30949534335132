import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import ButtonDX from "../controls/buttondx";
import { useState } from "react";
import ConfirmModal from "../controls/confirmmodal";
import LoadingButtonDX from "../controls/loadingbuttondx";

/**
 * JSX Component for Adding and Editing Data inside Dialog
 * @param {boolean} open boolean to open dialog
 * @param {Function} handleClose function to handle dialog close
 * @param { false | Breakpoint | undefined } maxWidth determine the max-width of the dialog
 * @param {string} title string title for dialog
 * @param {boolean} isChanged boolean to track change in data
 * @param {boolean} isSaving boolean to track data saving
 * @param {JSX.Element} children JSX Elements for dialog content
 * @param {Function} onSaveClick function to handle dialog close
 * @param {string} saveLabel string label for save button
 * @param {string} cancelLabel string label for cancel button
 */
const AddEditModalDX = (props: any) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const onClose = () => {
    if (props.isChanged) setOpen(true);
    else props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      fullWidth
      maxWidth={props.maxWidth ?? "xl"}
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
      {...props}
    >
      <DialogTitle sx={{ textAlign: "center" }}>{props.title}</DialogTitle>
      <DialogContent>
        {open && (
          <ConfirmModal
            open={open}
            onYesClick={props.handleClose}
            onNoClick={() => setOpen(false)}
          />
        )}
        {props.children}
      </DialogContent>
      <DialogActions sx={{ flexWrap: "wrap", justifyContent: "space-around" }}>
        <ButtonDX
          variant="outlined"
          onClick={onClose}
          sx={{ mb: { xs: 2, sm: "auto" } }}
        >
          {props.cancelLabel ?? t("Cancel")}
        </ButtonDX>
        <LoadingButtonDX
          // disabled={!props.isChanged}
          onClick={props.onSaveClick}
          loading={props.isSaving}
          // loadingPosition="end"
        >
          {props.saveLabel ?? t("Save")}
        </LoadingButtonDX>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditModalDX;
