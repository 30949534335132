import useSecureAPI from "../hooks/usesecureapi";

const useVMSDashboardService = () => {
  const secureAPI = useSecureAPI();

 
  const getStats = async (startDate: string, endDate: string) => {
    const response = await secureAPI.get(
      `vms/Dashboard/statistics/${startDate}/${endDate}`
    );
    return response.data;
  };

 
  return {
    getStats,
  };
};

export default useVMSDashboardService;
