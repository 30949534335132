import { useTranslation } from "react-i18next";
import BoxDX from "../../../layout/boxdx";
import DynamicTabs from "../../../layout/dynamictab";
import Inbox from "./inbox";

function VMSDashbordTabs() {
  const { t } = useTranslation();
  return (
    <BoxDX
      bgcolor={"white"}
      boxShadow={
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
      }
      sx={{ width: "100%", p: 1 }}
    >
      <DynamicTabs tabLabels={[t("Inbox")]} tabContents={[<Inbox />]} />
    </BoxDX>
  );
}

export default VMSDashbordTabs;
