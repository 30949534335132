import useSecureAPI from "../hooks/usesecureapi";

const useUserDelegationService = () => {
  const secureAPI = useSecureAPI();

  const getUserDelegations = async (id: number) => {
    const response = await secureAPI.get(`UserDelegation/byuser/${id}`);
    return response.data;
  };

  const getUserDelegationById = async (id: number) => {
    const response = await secureAPI.get(`UserDelegation/${id}`);
    return response.data;
  };

  const addUserDelegation = async (data: any) => {
    const response = await secureAPI.post("UserDelegation", data);
    return response.data;
  };

  const updateUserDelegation = async (data: any) => {
    const response = await secureAPI.put(`UserDelegation`, data);
    return response.data;
  };

  const deleteUserDelegation = async (id: number) => {
    const response = await secureAPI.delete(`UserDelegation/${id}`);
    return response.data;
  };

  return {
    getUserDelegations,
    getUserDelegationById,
    addUserDelegation,
    updateUserDelegation,
    deleteUserDelegation,
  };
};

export default useUserDelegationService;