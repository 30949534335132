import { Button, InputAdornment, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import ButtonDX from "../../components/controls/buttondx";
import TextFieldDX from "../../components/controls/textfielddx";
import GridDX from "../../components/layout/griddx";
import LoginBox from "../../components/layout/loginbox";
import LoadingOverlay from "../../components/loadingoverlay";
import { useAuthContext } from "../../context/authcontext";
import { useNotificationContext } from "../../context/notificationcontext";
import { login } from "../../shared/services/authservice";
import CheckBoxDX from "../../components/controls/checkboxdx";

const LoginPage = () => {
  const defaultValues = {
    userName: "",
    password: "",
  };

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { signIn } = useAuthContext();
  const { setError } = useNotificationContext();

  const [errors, setErrors] = useState<any>();
  const [emailError, setEmailError] = useState<any>();
  const [open, setOpen] = useState<boolean>(true);
  const [remeberMe, setRememberMe] = useState<boolean>(false);
  const [tooltipemail, setTooltipEmail] = useState<boolean>(true);
  const [formValues, setFormValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  // Inside your LoginPage component (useEffect)
  useEffect(() => {
    const rememberMePreference = localStorage.getItem("hexmateAppSession");
    if (rememberMePreference) {
      try {
        const sessionInfoJSON = JSON.parse(rememberMePreference);
        setRememberMe(sessionInfoJSON.rememberMe || false);
        setFormValues({
          userName: sessionInfoJSON.email,
          password: sessionInfoJSON.password,
        });
      } catch (ex) {
        console.log("Error parsing session info:", ex);
      }
    }
  }, []);

  useEffect(() => {
    validatePassword(formValues.password);
    validateEmail(formValues.userName);
  }, [formValues.password, formValues.userName]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  function validatePassword(pass: any) {
    const isContainsUppercase = /^(?=.*[A-Z])/;
    const isValidLength = /^.{8,}$/;
    const isContainsNumber = /^(?=.*[0-9])/;
    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
    const isContainsLowercase = /^(?=.*[a-z])/;

    if (!isValidLength.test(pass)) {
      setErrors(t("Password must be 8 Characters Long."));
      setOpen(true);
    } else if (!isContainsNumber.test(pass)) {
      setErrors(t("Password must have at least one Digit."));
      setOpen(true);
    } else if (!isContainsUppercase.test(pass)) {
      setErrors(t("Password must have at least one Uppercase Character."));
      setOpen(true);
    } else if (!isContainsLowercase.test(pass)) {
      setErrors(t("Password must have at least one Lowercase Character."));
      setOpen(true);
    } else if (!isContainsSymbol.test(pass)) {
      setErrors(t("Password must contain at least one Special Symbol."));
      setOpen(true);
    } else {
      setOpen(false);
    }
  }

  function validateEmail(email: any) {
    const validEmail = /^\S+@\S+\.\S+$/;
    if (!validEmail.test(email)) {
      setEmailError(t("Enter Valid User Name"));
      setTooltipEmail(true);
    } else {
      setTooltipEmail(false);
      setEmailError("");
    }
  }

  const onSubmit = () => {
    let request = {
      username: formValues.userName,
      password: formValues.password,
    };
    setIsLoading(true);
    login(request)
      .then((response) => {
        const { authData } = response;
        const userData = remeberMe
          ? {
              ...authData,
              rememberMe: remeberMe,
              email: formValues.userName,
              password: formValues.password,
            }
          : {
              ...authData,
              rememberMe: remeberMe,
            };

        signIn(userData, authData.tokenInfo.token);
        navigate("/welcome");
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <LoginBox title={t("Sign-In")}>
        <GridDX container rowSpacing={4}>
          <GridDX item xs={12} justifyContent="center">
            <Tooltip title={emailError} arrow open={tooltipemail}>
              <TextFieldDX
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                name="userName"
                value={formValues.userName}
                label={t("User Name")}
                onChange={handleInputChange}
              />
            </Tooltip>
          </GridDX>
          <GridDX item xs={12} justifyContent="center" direction="column">
            <Tooltip title={errors} arrow open={open}>
              <TextFieldDX
                fullWidth
                name="password"
                type={passwordVisible ? "text" : "password"}
                label={t("Password")}
                value={formValues.password}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {passwordVisible ? (
                        <VisibilityOffIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        />
                      ) : (
                        <VisibilityIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
            <CheckBoxDX
              label={t("Remember Me")}
              checked={remeberMe}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRememberMe(e.target.checked)
              }
            />
            <Button variant="text">
              <Typography
                fontSize={12}
                onClick={() => navigate("/forget-password")}
              >
                {t("Forgot my password")}
              </Typography>
            </Button>
          </GridDX>
          <GridDX item xs={12} justifyContent="center">
            <ButtonDX
              fullWidth
              startIcon={
                <ArrowRightAltIcon
                  sx={{
                    transform:
                      i18n.dir() === "rtl" ? "rotate(180deg)" : "inherit",
                  }}
                />
              }
              onClick={onSubmit}
              disabled={open ? true : false}
            >
              {t("Sign In")}
            </ButtonDX>
          </GridDX>
        </GridDX>
      </LoginBox>
    </>
  );
};

export default LoginPage;
