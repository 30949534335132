import { useEffect, useState } from "react";
import GridDX from "../../../components/layout/griddx";
import AppointmentListTabs from "./appointmentlisttabs";
import PageTitle from "../../../components/pagetitle";
import { useAuthContext } from "../../../context/authcontext";
import moment from "moment";
import FilterRange from "../../../components/controls/filterrange";
import { useTranslation } from "react-i18next";
import momentHijri from "moment-hijri";
import { useNotificationContext } from "../../../context/notificationcontext";
import { APIDateFormatter } from "../../../shared/globals";

const Appointments = () => {
  const { userData } = useAuthContext();
  const { setError } = useNotificationContext();
  const { calendarId } = userData;
  const { t } = useTranslation();
  const [filterFromDate, setFilterFromDate] = useState<any>(
    calendarId == 1
      ? moment().subtract(30, "days")
      : momentHijri().subtract(30, "days")
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    calendarId == 1 ? moment() : momentHijri()
  );
  const handleChangeFromDate = (date: any) => {
    setFilterFromDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));
    if (filterToDate != null && filterDate > filterToDate) {
      setError(t("From date must be less than to date"));
      setFilterFromDate(null);
    }
  };
  const handleChangeToDate = (date: any) => {
    setFilterToDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));

    if (filterFromDate != null && filterDate < filterFromDate) {
      setError(t("To date must be greater than from date"));
      setFilterToDate(null);
    }
  };

  useEffect(() => {}, [filterFromDate, filterToDate]);

  return (
    <GridDX
      container
      sx={{ width: "100%", alignItems: "center" }}
      rowSpacing={2}
      columnSpacing={1}
    >
      <GridDX item xs={2}>
        <PageTitle />
      </GridDX>
      <GridDX item xs={10} justifyContent="end">
        <FilterRange
          filterFromDate={filterFromDate}
          filterToDate={filterToDate}
          handleChangeFromDate={handleChangeFromDate}
          handleChangeToDate={handleChangeToDate}
        />
      </GridDX>
      <GridDX item xs={12}>
        <AppointmentListTabs
          filterFromDate={APIDateFormatter(filterFromDate)}
          filterToDate={APIDateFormatter(filterToDate)}
        />
      </GridDX>
    </GridDX>
  );
};

export default Appointments;
