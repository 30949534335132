import useSecureAPI from "../hooks/usesecureapi";

const useCorrespondenceService = () => {
  const secureAPI = useSecureAPI();

  const getCorrespondenceList = async () => {
    const response = await secureAPI.get("Correspondence");
    return response.data;
  };



  const getFilteredCorrespondenceList = async (startDate: any, endDate: any, type: any, domainType: any) => {
    const response = await secureAPI.get(`Correspondence/filtered/${startDate}/${endDate}/${type}/${domainType}`);
    return response.data;
  };

  const getArchivedCorrespondenceList = async () => {
    const response = await secureAPI.get("Correspondence/archived");
    return response.data;
  };

  const getFilteredArchivedCorrespondenceList = async (startDate: any, endDate: any, type: any, domainType: any) => {
    const response = await secureAPI.get(`Correspondence/archived/filtered/${startDate}/${endDate}/${type}/${domainType}`);
    return response.data;
  }

  const getCorrespondenceDetails = async (id: number) => {
    const response = await secureAPI.get(`Correspondence/${id}`);
    return response.data;
  };

  const getCorrespondenceStats = async (id: number) => {
    const response = await secureAPI.get(`Correspondence/statistics/${id}`);
    return response.data;
  };

  const addCorrespondence = async (data: any) => {
    const response = await secureAPI.post("Correspondence", data);
    return response.data;
  };

  const addCorrespondenceWithContent = async (data: any) => {
    const response = await secureAPI.post("Correspondence/fromcontent", data);
    return response.data;
  };

  const updateCorrespondence = async (data: any) => {
    const response = await secureAPI.put(`Correspondence`, data);
    return response.data;
  };

  const deleteCorrespondence = async (id: number) => {
    const response = await secureAPI.delete(`Correspondence/${id}`);
    return response.data;
  };

  const updateAttachment = async (data: any) => {
    const response = await secureAPI.put(
      `Correspondence/updateattachment`,
      data
    );
    return response.data;
  };

  const removeAttachment = async (correspondenceId: number) => {
    const response = await secureAPI.put(
      `Correspondence/removeattachment/${correspondenceId}`
    );
    return response.data;
  };

  return {
    getCorrespondenceList,
    getArchivedCorrespondenceList,
    getCorrespondenceDetails,
    addCorrespondence,
    addCorrespondenceWithContent,
    updateCorrespondence,
    deleteCorrespondence,
    getCorrespondenceStats,
    updateAttachment,
    removeAttachment,
    getFilteredCorrespondenceList,
    getFilteredArchivedCorrespondenceList
  };
};

export default useCorrespondenceService;
