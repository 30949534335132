import { useState, useEffect } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import AttachmentModal from "../../../../components/editmodals/correspondence/correspondenceattachmentsmodal";
import ListPageDX from "../../../../components/business/listpagedx";

import useAttachmentService from "../../../../shared/services/attachmentservice";
import {
  CorrespondenceStatus,
  DateTimeFormatter,
  DateSorter,
  concatenateNameWithRole,
} from "../../../../shared/globals";

import { useNotificationContext } from "../../../../context/notificationcontext";
import { useAuthContext } from "../../../../context/authcontext";
import AttachmentViewerModal from "../../../../components/editmodals/correspondence/attachmentviewermodal";

const CorrespondenceAttachments = (props: any) => {
  const { status } = props;
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { getAttachments, deleteAttachment } = useAttachmentService();
  const { setError, setInfo } = useNotificationContext();

  const [rows, setRows] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [attachment, setAttachment] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [openViewer, setOpenViewer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAttachmentsList();
  }, [i18n.language]);

  const getAttachmentsList = async () => {
    setIsLoading(true);
    getAttachments(props.correspondenceId)
      .then((response: any) => {
        setAttachments(response);
        setRows(response);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: any) => {
    setIsLoading(true);
    deleteAttachment(id)
      .then(() => {
        setInfo(t("Data deleted successfully"));
        getAttachmentsList();
        props.getDetails(props.correspondenceId);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toCreate = () => {
    setOpen(true);
  };

  const toView = (row: any) => {
    setIsLoading(true);
    // const fileURL = `https://api.hexmate.compass-dx.com/api/Attachment/download/${row.attachmentId}`;
    const fileURL = row.dirLocation;
    fetch(fileURL, {
      headers: { Authorization: `Bearer ${userData.tokenInfo.token}` },
    })
      .then(async (res) => {
        const blob = await res.blob();
        const link = window.URL.createObjectURL(blob);
        const data = {
          enName: row.enName,
          arName: row.arName,
          link: link,
        };
        setAttachment(data);
        setOpenViewer(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toClose = (refreshPage: boolean = false) => {
    setOpen(false);

    if (refreshPage) {
      getAttachmentsList();
      props.getDetails(props.correspondenceId);
    }
  };

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      width: 250,
    },
    {
      field: "description",
      headerName: `${t("Description")}`,
      flex: 1,
      minWidth: 300,
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,

      valueGetter: (params: any) => {
        return (
          params.row.createdByUser &&
          concatenateNameWithRole(params.row.createdByUser)
        );
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Date Added")}`,
      flex: 1,
      valueGetter(params) {
        return DateTimeFormatter(
          params.row.createdDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
      sortComparator: DateSorter,
    },
  ];

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(attachments);
      return;
    }

    const lowercasedValue = value.toLowerCase();

    const newRows = attachments.filter(
      ({ enName, arName, description, createdByUser }: any) =>
        languageIsEn
          ? enName.toLowerCase().includes(lowercasedValue) ||
            description.toLowerCase().includes(lowercasedValue) ||
            createdByUser.enFullName.toLowerCase().includes(lowercasedValue)
          : arName.includes(value) ||
            description.toLowerCase().includes(lowercasedValue) ||
            createdByUser.arFullName.includes(value)
    );
    setRows(newRows);
  };

  return (
    <>
      {open && (
        <AttachmentModal
          open={open}
          handleClose={toClose}
          correspondenceId={props.correspondenceId}
        />
      )}
      {openViewer && (
        <AttachmentViewerModal
          open={openViewer}
          fileName={
            i18n.language === "en" ? attachment.enName : attachment.arName
          }
          fileURL={attachment.link}
          applyWatermark={props.applyWatermark}
          handleClose={() => setOpenViewer(false)}
        />
      )}
      <ListPageDX
        listTitle={t("Attachments")}
        name={t("Attachment")}
        createLabel={t("Add Attachments")}
        editLabel={t("View")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.attachmentId}
        isLoading={isLoading}
        toCreate={
          status == CorrespondenceStatus.Recall ||
          status == CorrespondenceStatus.Draft
            ? toCreate
            : null
        }
        onDelete={
          status == CorrespondenceStatus.Recall ||
          status == CorrespondenceStatus.Draft
            ? onDelete
            : null
        }
        onEdit={toView}
        exportToPDF={false}
        exportToCSV={false}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default CorrespondenceAttachments;
