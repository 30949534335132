import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import VisitorGrid from "./visitorgrid";
import AppointmentCard from "./appointmentcard";
import BoxDX from "../../../components/layout/boxdx";

const CheckinsListingAccordion = ({ e, countries, setAppointments }: any) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <BoxDX sx={{ width: "100%" }}>
      <Accordion
        expanded={expanded}
        sx={{
          boxShadow: "0",
          padding: 0,
          border: "1px solid #e5e5e5",
          borderRadius: "10px",
        }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            width: "100%",
            cursor: "default !important",
            padding: 0,
          }}
        >
          <AppointmentCard
            data={e}
            handleExpand={handleExpand}
            expanded={expanded}
          />
        </AccordionSummary>
        <AccordionDetails>
          <VisitorGrid
            data={e.visitors}
            setAppointments={setAppointments}
            countries={countries}
          />
        </AccordionDetails>
      </Accordion>
    </BoxDX>
  );
};

export default CheckinsListingAccordion;
