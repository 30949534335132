import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GridDX from "../components/layout/griddx";
import AppBarDX from "../components/business/appbar/mainappbardx";
import NotificationBarDX from "../components/notificationbardx";
import BoxDX from "../components/layout/boxdx";
import MiniDrawer from "../components/business/menu/collapsemenu";

const MainTemplate = () => {
  const { i18n } = useTranslation();

  return (
    <BoxDX
      sx={{
        minHeight: "100vh",
        width: "100%",
        backgroundColor: "#FAFBFF",
        display: "flex",
      }}
      dir={i18n.dir()}
    >
      <AppBarDX />

      <GridDX
        container
        sx={{ width: "100%", marginTop: 64 }}
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <GridDX item xs={12} sx={{ height: "100%" }}>
          <BoxDX
            sx={{
              display: { xs: "none", md: "flex" },
              overflow: "no-scroll",
            }}
          >
            <MiniDrawer />
          </BoxDX>
          <BoxDX
            sx={{
              width: "100%",
              height: "calc(100vh - 64px)",
              overflowY: "scroll",
              overflowX: "auto",
              p: 2,
            }}
          >
            <NotificationBarDX />
            <Outlet />
          </BoxDX>
        </GridDX>
      </GridDX>
    </BoxDX>
  );
};

export default MainTemplate;
