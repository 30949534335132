import useSecureAPI from "../hooks/usesecureapi";

const useDashboardService = () => {
  const secureAPI = useSecureAPI();

  const getCorrespondenceTotalCount = async (
    startDate: string,
    endDate: string
  ) => {
    const response = await secureAPI.get(
      `Dashboard/correspondence/totalcount/${startDate}/${endDate}`
    );
    return response.data;
  };

  const getClosedCorrespondenceTotalCount = async (
    startDate: string,
    endDate: string
  ) => {
    const response = await secureAPI.get(
      `Dashboard/correspondence/closedcount/${startDate}/${endDate}`
    );
    return response.data;
  };

  const getAvgClosureDaysCount = async (startDate: string, endDate: string) => {
    const response = await secureAPI.get(
      `Dashboard/correspondence/averageclosuredays/${startDate}/${endDate}`
    );
    return response.data;
  };

  const getOverDueCorrespondenceCount = async (
    startDate: string,
    endDate: string
  ) => {
    const response = await secureAPI.get(
      `Dashboard/correspondence/overduecount/${startDate}/${endDate}`
    );
    return response.data;
  };

  const getCorrespondenceCountByType = async (
    startDate: string,
    endDate: string
  ) => {
    const response = await secureAPI.get(
      `Dashboard/correspondence/bytype/${startDate}/${endDate}`
    );
    return response.data;
  };

  const getCorrespondenceCountByStatus = async (
    startDate: string,
    endDate: string
  ) => {
    const response = await secureAPI.get(
      `Dashboard/correspondence/bystatus/${startDate}/${endDate}`
    );
    return response.data;
  };

  const getMonthlyStatistics = async (startDate: string, endDate: string) => {
    const response = await secureAPI.get(
      `Dashboard/correspondence/monthlyperformance/${startDate}/${endDate}`
    );
    return response.data;
  };

  const getStats = async (startDate: string, endDate: string) => {
    const response = await secureAPI.get(
      `Dashboard/statistics/${startDate}/${endDate}`
    );
    return response.data;
  };

  const getSentAndNotReceivedCount = async (startDate: any, endDate: any, type: any, domainType: any) => {
    const response = await secureAPI.get(
      `dashboard/correspondence/sentandnotreceived/${startDate}/${endDate}/${type}/${domainType}`
    );
    return response.data;
  };

  const getRequiresReceivingCount = async (startDate: any, endDate: any, type: any, domainType: any) => {
    const response = await secureAPI.get(
      `dashboard/correspondence/requiresreceiving/${startDate}/${endDate}/${type}/${domainType}`
    );
    return response.data;
  };

  const getRequiresMyActionCount = async (startDate: any, endDate: any, type: any, domainType: any) => {
    const response = await secureAPI.get(
      `dashboard/correspondence/requiresmyaction/${startDate}/${endDate}/${type}/${domainType}`
    );
    return response.data;
  };

  const getOverDueBreakupCount = async (startDate: any, endDate: any, type: any, domainType: any) => {
    const response = await secureAPI.get(
      `dashboard/correspondence/overduebreakup/${startDate}/${endDate}/${type}/${domainType}`
    );
    return response.data;
  };

  const getOpenBreakupCount = async (startDate: any, endDate: any, type: any, domainType: any) => {
    const response = await secureAPI.get(
      `dashboard/correspondence/openbreakup/${startDate}/${endDate}/${type}/${domainType}`
    );
    return response.data;
  };

  return {
    getCorrespondenceTotalCount,
    getClosedCorrespondenceTotalCount,
    getAvgClosureDaysCount,
    getOverDueCorrespondenceCount,
    getCorrespondenceCountByType,
    getCorrespondenceCountByStatus,
    getMonthlyStatistics,
    getStats,
    getSentAndNotReceivedCount,
    getRequiresReceivingCount,
    getRequiresMyActionCount,
    getOverDueBreakupCount,
    getOpenBreakupCount,
  };
};

export default useDashboardService;
