import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";

import { useNotificationContext } from "../../../../context/notificationcontext";
import { useAuthContext } from "../../../../context/authcontext";

import CorrespondenceRemindersModal from "../../../../components/editmodals/correspondence/correspondenceremindersmodal";
import ListPageDX from "../../../../components/business/listpagedx";

import useCorrespondenceReminderService from "../../../../shared/services/correspondenceremidersservice";
import {
  CorrespondenceStatus,
  DateTimeFormatter,
  DateSorter,
  concatenateNameWithRole,
} from "../../../../shared/globals";

const CorrespondenceReminders = (props: any) => {
  const { status } = props;

  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;

  const { t, i18n } = useTranslation();
  const { correspondenceId } = props;
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();

  const { getRemindersByCorrespondenceID, deleteCorrespondenceReminder } =
    useCorrespondenceReminderService();

  const [rows, setRows] = useState<any>([]);

  const [reminderID, setReminderID] = useState<any>(null);

  const [reminders, setReminders] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "description",
      headerName: `${t("Reminder Description")}`,
      flex: 1,
    },
    {
      field: "reminderDate",
      headerName: `${t("Reminder Date")}`,
      flex: 1,
      valueGetter(params) {
        return DateTimeFormatter(
          params.row.reminderDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
      sortComparator: DateSorter,
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,

      valueGetter: (params: any) => {
        return (
          params.row.createdByUser &&
          concatenateNameWithRole(params.row.createdByUser)
        );
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Date Added")}`,
      flex: 1,
      valueGetter(params) {
        return DateTimeFormatter(
          params.row.createdDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
      sortComparator: DateSorter,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getRemindersByCorrespondenceID(correspondenceId) // api to fetch data types from the server
      .then((response) => {
        setRows(response);
        setReminders(response);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteCorrespondenceReminder(id)
      .then(() => {
        setInfo(t("Correspondence reminder deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toCreate = () => {
    setOpen(!open);
  };
  const onEdit = (data: any) => {
    setReminderID(data.reminderId);
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setOpen(!open);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(reminders);
      return;
    }

    const lowercasedValue = value.toLowerCase();
    const newRows = reminders.filter(({ description, createdByUser }: any) =>
      languageIsEn
        ? description.toLowerCase().includes(lowercasedValue) ||
          createdByUser.enFullName.toLowerCase().includes(lowercasedValue)
        : description.includes(value) ||
          createdByUser.arFullName.includes(value)
    );
    setRows(newRows);
  };
  return (
    <>
      {open && (
        <CorrespondenceRemindersModal
          open={open}
          handleClose={onClose}
          correspondenceId={correspondenceId}
          correspondenceReminderId={reminderID}
        />
      )}
      <ListPageDX
        listTitle={t("Correspondence Reminders")}
        name={t("Reminder")}
        createLabel={t("Add Reminder")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.reminderId}
        isLoading={isLoading}
        toCreate={
          status == CorrespondenceStatus.Recall ||
          status == CorrespondenceStatus.Draft
            ? toCreate
            : null
        }
        onDelete={
          status == CorrespondenceStatus.Recall ||
          status == CorrespondenceStatus.Draft
            ? onDelete
            : null
        }
        onEdit={
          status == CorrespondenceStatus.Recall ||
          status == CorrespondenceStatus.Draft
            ? onEdit
            : null
        }
        setGridFilterCriteria={setGridFilterCriteria}
        exportToPDF={false}
        exportToCSV={false}
      />
    </>
  );
};

export default CorrespondenceReminders;
