import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import Skeleton from "react-loading-skeleton";
import { AdapterMomentHijri } from "@mui/x-date-pickers/AdapterMomentHijri";
import momentHijri from "moment-hijri";
import { useTranslation } from "react-i18next";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  DATE_FORMAT,
  DATE_FORMAT_LONG,
  HIJRI_DATE_FORMAT,
  HIJRI_DATE_FORMAT_LONG,
} from "../../shared/globals";
import { useAuthContext } from "../../context/authcontext";

const DatePickerDX = (props: any) => {
  const { userData } = useAuthContext();
  const { calendarId, hijriDateFormatId, gregorianDateFormatId } = userData;
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const useMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const isError = props.error ? true : false;
  const errorText = props.error ?? "";
  const helperText = props.helperText;
  const dateFormat = props.format ?? DATE_FORMAT; //DATE_FORMAT;
  const platform = navigator?.userAgent ?? "";
  const loading = props.loading ?? false;
  const fontSize = props.fontSize ?? "medium";

  const CalendarStyle =
    i18n.dir() === "rtl"
      ? {
          ...props.sx,
          "& label": {
            left: "unset",
            right: "1.75rem",
            transformOrigin: "right",
          },
          "& legend": {
            textAlign: "right",
          },
          "& .MuiFormHelperText-root": {
            textAlign: "right",
          },
          "& .MuiSelect-icon": {
            left: 0, // Change the position of the icon to the left
            right: "auto", // Reset the right property
          },
        }
      : { ...props.sx };

  if (loading)
    return (
      <Skeleton
        containerClassName="skeleton-container"
        style={{ height: 56 }}
      />
    );
  else if (props.readOnly)
    return (
      <TextField
        {...props}
        value={props.value ? moment(props.value).format(dateFormat) : ""}
        variant="outlined"
        fullWidth
        disabled
        placeholder={helperText}
        helperText=""
        InputLabelProps={{ shrink: true }}
      />
    );
  else if (calendarId == 2)
    return (
      <LocalizationProvider
        dateAdapter={AdapterMomentHijri}
        adapterLocale={i18n.language}
      >
        <DatePicker
          {...props}
          // moment-hijri support dates bewteen 1356-01-01 and 1499-12-29 H (1937-03-14 and 2076-11-26)
          maxDate={props.maxDate || momentHijri(new Date(2075, 11, 31))}
          variant="outlined"
          inputVariant="outlined"
          slotProps={{
            textField: {
              error: isError,
              helperText: errorText ?? helperText,
            },
            popper: {
              popperOptions: {
                placement: "top",
              },
              sx: {
                height: "200px !important",
              },
            },
          }}
          value={props.value && momentHijri(props.value)}
          minDate={momentHijri(props.minDate)}
          format={
            props.format == "YYYY"
              ? "iYYYY"
              : props.format == "YYYY-MM"
              ? "iYYYY-iMM"
              : props.format == "YYYY-MM-DD"
              ? "iYYYY-iMM-iDD"
              : hijriDateFormatId == 1
              ? HIJRI_DATE_FORMAT
              : HIJRI_DATE_FORMAT_LONG
          }
          sx={CalendarStyle}
        />
      </LocalizationProvider>
    );
  else {
    return (
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={i18n.language}
      >
        <DatePicker
          {...props}
          variant="outlined"
          inputVariant="outlined"
          slotProps={{
            textField: {
              error: isError,
              helperText: errorText ?? helperText,
            },
            popper: {
              popperOptions: {
                placement: "top",
              },
              sx: {
                height: "200px !important",
              },
            },
          }}
          value={props.value && moment(props.value)}
          format={
            props.format
              ? props.format
              : gregorianDateFormatId == 1
              ? DATE_FORMAT
              : DATE_FORMAT_LONG
          }
          sx={CalendarStyle}
          maxDate={props.maxDate || moment(new Date(9999, 11, 31))}
        />
      </LocalizationProvider>
    );
  }
};

export default DatePickerDX;
