import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import ListPageDX from "../../../components/business/listpagedx";
import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DateSorter, DateFormatter } from "../../../shared/globals";
import { appointmentsData } from "../../../dummydatavms";
import TypeTranslator from "../../../shared/typetranslator";
import useAppointmentService from "../../../shared/services/vmsappointmentservice";

const ArchivedAppointmentsList = (props: any) => {
  const { filterFromDate, filterToDate } = props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const { getArchivedAppointments, deleteAppointment } =
    useAppointmentService();
  const { userData } = useAuthContext();
  const { getAppointmentTypeValue, getVisitPurposeValue } = TypeTranslator();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "appointmentType",
      headerName: `${t("Appointment Type")}`,
      flex: 1,
      valueGetter: (params: any) =>
        getAppointmentTypeValue(params?.row?.appointmentTypeId),
    },
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.organizationName;
        // params.row.appointmentTypeId == 1
        //   ? params.value
        //   : languageIsEn
        //   ? params.row.organizationName
        //   : params.row.companyArName;
      },
    },
    {
      field: "visitPurpose",
      headerName: `${t("Visit Purpose")}`,
      flex: 1,
    },
    {
      field: "visitFromDate",
      headerName: `${t("From")}`,
      flex: 1,
      valueFormatter: (params) =>
        DateFormatter(
          params.value,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        ),
      sortComparator: DateSorter,
    },
    {
      field: "visitEndDate",
      headerName: `${t("To")}`,
      flex: 1,
      valueFormatter: (params) =>
        DateFormatter(
          params.value,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        ),
      sortComparator: DateSorter,
    },
  ];
  const getData = () => {
    setIsLoading(true);
    getArchivedAppointments()
      .then((appointments) => {
        const formatedData = appointments.map((data: any) => {
          return {
            ...data,
            visitFromDate: DateFormatter(
              data.visitFromDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
            visitEndDate: DateFormatter(
              data.visitEndDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
            appointmentType: getAppointmentTypeValue(data.appointmentTypeId),
          };
        });
        setRows(formatedData);
        setDataFromApi(formatedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();
  }, [i18n.language]);
  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = dataFromApi.filter(
      ({
        enName,
        arName,
        organizationName,
        visitFromDate,
        visitEndDate,
        appointmentTypeId,
        visitPurpose,
      }: any) =>
        languageIsEn
          ? enName?.toLowerCase().includes(lowercasedValue) ||
            organizationName?.toLowerCase().includes(lowercasedValue) ||
            visitFromDate?.includes(value) ||
            visitEndDate?.includes(value) ||
            getAppointmentTypeValue(appointmentTypeId)
              ?.toString()
              ?.toLowerCase()
              .includes(lowercasedValue) ||
            visitPurpose.toString()?.toLowerCase().includes(lowercasedValue)
          : arName?.includes(value) ||
            organizationName?.includes(value) ||
            visitFromDate?.includes(value) ||
            visitEndDate?.includes(value) ||
            getAppointmentTypeValue(appointmentTypeId)
              ?.toString()
              ?.toLowerCase()
              .includes(lowercasedValue) ||
            visitPurpose.toString()?.toLowerCase().includes(lowercasedValue)
    );
    setRows(newRows);
  };
  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteAppointment(id)
      .then(() => {
        setInfo(t("Appointment deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  return (
    <ListPageDX
      listTitle={t("Archived Appointments")}
      name={t("Archived Appointment")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.appointmentId}
      isLoading={isLoading}
      setGridFilterCriteria={setGridFilterCriteria}
      onDelete={onDelete}
    />
  );
};

export default ArchivedAppointmentsList;
