import { TextField, InputAdornment, IconButton } from "@mui/material/";
import Autocomplete from "@mui/material/Autocomplete";
import Skeleton from "react-loading-skeleton";
import { CloseOutlined } from "@mui/icons-material";

const AutoCompleteListDX = (props: any) => {
  const helperText = props.helperText ?? "";
  const checkValue = props.value ?? "";
  const isError = (props.errorText || props.error) ?? false;
  const errorText = (props.errorText || props.error) ?? "";
  const loading = props.loading ?? false;
  const list = props.list || props.items || [];
  const noOptionsText = props.noOptionsText || "No Options";

  if (loading)
    return (
      <Skeleton
        containerClassName="skeleton-container"
        style={{ height: 56 }}
      />
    );
  else if (props.readOnly) {
    return (
      <TextField
        {...props}
        variant="standard"
        fullWidth
        multiline
        disabled
        placeholder={helperText}
        helperText=""
        InputLabelProps={{ shrink: true }}
        value={checkValue}
      ></TextField>
    );
  } else {
    return (
      <Autocomplete
        fullWidth
        disableClearable={false}
        clearIcon
        options={list}
        autoHighlight
        getOptionLabel={(option: any) => option.text || option.value || ""}
        {...props}
        value={list.find((option: any) => option?.value === checkValue) || null}
        noOptionsText={noOptionsText}
        onChange={(event, newValue: any) => {
          if (newValue) {
            props.onChange({
              target: {
                name: props.name,
                value: newValue.value,
              },
            });
          }
        }}
        sx={{
          ...props.sx,
          "& .MuiAutocomplete-inputRoot": { paddingRight: "0 !important" },
          "& .MuiOutlinedInput-root": { paddingRight: "30px !important" }
        }}
        renderInput={(params) => {
          const defaultEndAdornment = params.InputProps.endAdornment;
          return (
            <TextField
              {...params}
              error={isError}
              helperText={errorText ?? props.helperText}
              label={props.label}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
              variant={!props.readOnly ? "outlined" : "standard"}
              disabled={props.readOnly || props.disabled}
              InputLabelProps={{ shrink: props.readOnly }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    {checkValue && (
                      <IconButton
                        onClick={() =>
                          props.onChange({
                            target: { name: props.name, value: null },
                          })
                        }
                      >
                        <CloseOutlined />
                      </IconButton>
                    )}
                  </>
                ),
              }}
            />
          );
        }}
      />
    );
  }
};

export default AutoCompleteListDX;
