import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonDX from "../../controls/buttondx";
import TextFieldDX from "../../controls/textfielddx";
import { useState } from "react";
import GridDX from "../../layout/griddx";
import ContentView from "../../pages/DMS/contentview";
import useDocumentService from "../../../shared/services/documentservice";
import { useNotificationContext } from "../../../context/notificationcontext";

const AdvancedSearchModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setError } = useNotificationContext();
  const { searchInDocuments } = useDocumentService();

  const [filterValue, setFilterValue] = useState("");
  const [documents, setDocuments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setFilterValue(value);
  };

  const toSearch = async () => {
    if (filterValue.length > 0) {
      setIsLoading(true);
      searchInDocuments(filterValue)
        .then((res) => setDocuments(res))
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const handleClose = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;
    props.toClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {t("Advanced Search")}
      </DialogTitle>
      <DialogContent>
        <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
          <GridDX item xs={9} sx={{ display: "flex", flexDirection: "column" }}>
            <TextFieldDX
              variant="outlined"
              placeholder={t("Search")}
              value={filterValue}
              sx={{
                backgroundColor: "#F2F2F2",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  padding: "8px",
                },
              }}
              onChange={handleInputChange}
              fullWidth
              onKeyPress={(e: any) => {
                if (e.key === "Enter") {
                  toSearch();
                }
              }}
            />
            {isLoading && <LinearProgress />}
          </GridDX>
          <GridDX item xs={3} sx={{ justifyContent: "center" }}>
            <ButtonDX onClick={toSearch}>{t("Search")}</ButtonDX>
          </GridDX>
          <GridDX item xs={12} sx={{ minHeight: 400 }}>
            <ContentView
              folders={[]}
              documents={documents}
              cabinetId={0}
              folderId={0}
              checkedFolders={[]}
              checkedFiles={[]}
              toSelect={() => {}}
              toRefresh={toSearch}
              onFolderChange={() => {}}
              forSearch={true}
            />
          </GridDX>
        </GridDX>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "end" }}>
        <ButtonDX onClick={props.toClose}>{t("Close")}</ButtonDX>
      </DialogActions>
    </Dialog>
  );
};

export default AdvancedSearchModal;
