import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../../components/business/listpagedx";
import { useNotificationContext } from "../../../context/notificationcontext";
import { concatenateNameWithRole, DateSorter, DateTimeFormatter } from "../../../shared/globals";
import DocumentEditorModal from "../../../components/editmodals/tools/documentEditorModal";

const DocumentEditorPage = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setInfo, setError } = useNotificationContext();
  const [documentEditorId, setDocumentEditorId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<any>([
    {
      id: 1,
      enName: "Ali Ahmed",
      arName: "علي أحمد",
      state: t("Open"),
      type: t("Document"),
      createdByUser: {
        enFullName: "John Doe",
        arFullName: "علي أحمد",
      },
      createdDate: new Date().toISOString(),
      modifiedDate: new Date().toISOString(),
    },
    {
      id: 2,
      enName: "Kholoud Mohammed",
      arName: "خلود محمد",
      state: t("Closed"),
      type: t("Image"),
      createdByUser: {
        enFullName: "Jane Doe",
        arFullName: "فاطمة محمد",
      },
      createdDate: new Date().toISOString(),
      modifiedDate: new Date().toISOString(),
    },
  ]);
  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      flex: 1,
    },

    {
      field: "state",
      headerName: `${t("State")}`,
      flex: 1,
    },
    {
      field: "type",
      headerName: `${t("Type")}`,
      flex: 1,
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return (
          params.row.createdByUser &&
          concatenateNameWithRole(params.row.createdByUser)
        );
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Created at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "modifiedDate",
      headerName: `${t("Last modified at")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
  ];

  const getData = () => {};

  const onDelete = (id: number) => {
    setRows(rows.filter((row: any) => row.id !== id));
    setInfo(t("Editable document deleted successfully"));
  };

  const onEdit = (editableDoc: any) => {
    setOpen(!open);
    setDocumentEditorId(editableDoc.id);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setOpen(!open);
    setDocumentEditorId(null);

    if (refreshPage) getData();
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);
  return (
    <>
      {open && (
        <DocumentEditorModal
          open={open}
          handleClose={onClose}
          documentEditorId={documentEditorId}
          rows={rows}
          setRows={setRows}
        />
      )}
      <ListPageDX
        listTitle={t("Editable Documents")}
        name={t("Editable Document")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.id}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        hideColumnsOption={true}
      />
    </>
  );
};

export default DocumentEditorPage;
