import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../../components/business/listpagedx";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DateSorter, DateTimeFormatter } from "../../../shared/globals";
import DocumentTypeModal from "../../../components/editmodals/DMS/documentTypeModal";
import { useAuthContext } from "../../../context/authcontext";
import useDocumentTypeService from "../../../shared/services/documenttypeservice";

const DocumentTypes = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { getDocumentTypes, deleteDocumentType } = useDocumentTypeService();
  const { setInfo, setError } = useNotificationContext();
  const [documentTypeId, setDocumentTypeId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<any>([]);

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: `${t("Created at")}`,
      flex: 1,
      valueGetter(params) {
        return DateTimeFormatter(
          params.row.createdDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
      sortComparator: DateSorter,
    },
    {
      field: "modifiedDate",
      headerName: `${t("Last modified at")}`,
      flex: 1,
      valueGetter(params) {
        return DateTimeFormatter(
          params.row.modifiedDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
      sortComparator: DateSorter,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    getDocumentTypes()
      .then((res: any) => setRows(res))
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteDocumentType(id)
      .then((res: any) => {
        setInfo(t("Document type deleted successfully"));
        getData();
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (documentType: any) => {
    setOpen(!open);
    setDocumentTypeId(documentType.documentTypeId);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setOpen(!open);
    setDocumentTypeId(null);

    if (refreshPage) getData();
  };

  return (
    <>
      {open && (
        <DocumentTypeModal
          open={open}
          handleClose={onClose}
          documentTypeId={documentTypeId}
        />
      )}
      <ListPageDX
        listTitle={t("Document Types")}
        name={t("Document type")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.documentTypeId}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </>
  );
};

export default DocumentTypes;
