import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import ButtonDX from "./buttondx";
import GridDX from "../layout/griddx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "./textfielddx";

const AppointmentConfirmModal = (props: any) => {
  const {
    open,
    onCancelClick,
    onConfirmClick,
    label,
    actionData,
    setActionData,
  } = props;
  const { t, i18n } = useTranslation();
  const handleConfirmClick = () => {
    const updatedActionData = {
      ...actionData,
      notes: actionData.notes, // Ensure notes are passed for rejection
    };
    onConfirmClick(updatedActionData);
    setActionData(() => ({ ...actionData, notes: "" }));
  };
  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      fullWidth
      maxWidth={"xs"}
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {t("Are You Sure?")}
      </DialogTitle>
      <DialogContent>
        <GridDX container justifyContent="center" direction="column">
          <Typography sx={{ textAlign: "center" }}>
            {t(`You want to ${label} this appointment?`)}
          </Typography>
          {label === "Reject" && (
            <GridDX sm={12} sx={{ mt: 1 }}>
              <TextFieldDX
                label={t("Reason")}
                name="notes"
                type="text"
                multiline
                rows={2}
                fullWidth
                value={actionData.notes}
                onChange={(e: any) => {
                  setActionData({ ...actionData, notes: e.target.value });
                }}
              />
            </GridDX>
          )}
        </GridDX>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <ButtonDX variant="outlined" onClick={() => onCancelClick()}>
          {t("Cancel")}
        </ButtonDX>
        <ButtonDX onClick={handleConfirmClick}>{t("Yes")}</ButtonDX>
      </DialogActions>
    </Dialog>
  );
};

export default AppointmentConfirmModal;
