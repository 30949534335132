import React from "react";
import DataGridDX from "../../../components/layout/datagriddx";
import { useTranslation } from "react-i18next";
import ButtonDX from "../../../components/controls/buttondx";
import { Link, Typography } from "@mui/material";
import TypeTranslator from "../../../shared/typetranslator";
import BoxDX from "../../../components/layout/boxdx";
import useCheckinService from "../../../shared/services/vmscheckinservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DateTimeFormatter, toPreviewDocument } from "../../../shared/globals";
import { useAuthContext } from "../../../context/authcontext";
import ConfirmModal from "../../../components/controls/confirmmodal";

const VisitorGrid = ({
  data,
  countries,
  setAppointments,
}: any) => {
  const { t } = useTranslation();
  const { getIdTypeValue } = TypeTranslator();
  const { checkinVisitor, checkoutVisitor } = useCheckinService();
  const { setInfo, setError } = useNotificationContext();
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const [isLoading, setIsLoading] = React.useState(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [selectedVisitorId, setSelectedVisitorId] = React.useState(null);
  const visitorColumns = [
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
    },
    {
      field: "countryId",
      headerName: t("Country"),
      flex: 1,
      valueGetter: (params: any) => {
        return countries.find((c: any) => c.countryId === params.value)?.enName;
      },
    },
    {
      field: "contactNumber",
      headerName: t("Contact Number"),
      flex: 1,
    },
    {
      field: "idTypeId",
      headerName: t("ID Details"),
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              toPreviewDocument(
                params.row.visitorDocuments[0],
                userData,
                setError,
                setIsLoading
              );
            }}
            sx={{ textAlign: "left" }}
          >
            <Typography variant="body2">
              {getIdTypeValue(params.row.idTypeId)}
            </Typography>
            <Typography variant="body2">{params.row.idDetails}</Typography>
          </Link>
        );
      },
    },
    {
      field: "vehicleRegistrationNumber",
      headerName: t("Vehicle"),
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              toPreviewDocument(
                params.row.visitorDocuments[1],
                userData,
                setError,
                setIsLoading
              );
            }}
            sx={{ textAlign: "left" }}
          >
            <Typography variant="body2">
              {getIdTypeValue(params.row.vehicleRegistrationNumber)}
            </Typography>
            <Typography variant="body2">{params.row.vehicleColor}</Typography>
          </Link>
        );
      },
    },
    {
      field: "checkin",
      headerName: t(""),
      type: "actions",
      flex: 1,
      renderCell: (params: any) => {
        const isCheckIn =
          params.row.lastVisitStatus === 1 || params.row.lastVisitStatus === 3;
        const handleClick = isCheckIn
          ? () => handleCheckinClick(params.row.visitorId)
          : () => handleCheckoutClick(params.row.visitorId);

        return (
          <BoxDX>
            <ButtonDX sx={{ my: 0.5 }} size="small" onClick={handleClick}>
              {isCheckIn ? t("Check In") : t("Check Out")}
            </ButtonDX>

            {params.row.lastVisitStatusDatetime && (
              <Typography variant="body2" sx={{ my: 0.5 }}>
                {DateTimeFormatter(
                  params.row.lastVisitStatusDatetime,
                  calendarId,
                  gregorianDateFormatId,
                  hijriDateFormatId
                )}
              </Typography>
            )}
          </BoxDX>
        );
      },
    },
  ];

  // const handleCheckinClick = (visitorId: any) => {
  //   setIsLoading(true);
  //   checkinVisitor(visitorId)
  //     .then(() => {
  //       setAppointments(
  //         appointments.map((appointment: any) => {
  //           if (
  //             data.find(
  //               (d: any) => d.appointmentId === appointment.appointmentId
  //             )
  //           ) {
  //             return {
  //               ...appointment,
  //               visitors: appointment.visitors.map((visitor: any) => {
  //                 const matchingVisitor = data.find(
  //                   (d: any) =>
  //                     d.appointmentId === appointment.appointmentId &&
  //                     d.visitorId === visitor.visitorId
  //                 );
  //                 console.log("matchingVisitor", matchingVisitor);

  //                 if (matchingVisitor) {
  //                   return {
  //                     ...visitor,
  //                     lastVisitStatus: 2,
  //                     lastVisitStatusDatetime: new Date(),
  //                   };
  //                 }
  //                 return visitor;
  //               }),
  //             };
  //           }
  //           return appointment;
  //         })
  //       );
  //       setInfo(t("Visitor checked in successfully"));
  //     })
  //     .catch((error) => {
  //       setError(error);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //       setOpenConfirmModal(false);
  //     });
  // };

  // const handleCheckoutClick = (visitorId: any) => {
  //   setSelectedVisitorId(visitorId);
  //   setOpenConfirmModal(true);
  // };

  // const handleConfirmYes = () => {
  //   setIsLoading(true);
  //   checkoutVisitor(selectedVisitorId)
  //     .then(() => {
  //       setAppointments(
  //         appointments.map((appointment: any) => {
  //           if (
  //             data.find(
  //               (d: any) => d.appointmentId === appointment.appointmentId
  //             )
  //           ) {
  //             return {
  //               ...appointment,
  //               visitors: appointment.visitors.map((visitor: any) => {
  //                 const matchingVisitor = data.find(
  //                   (d: any) =>
  //                     d.appointmentId === appointment.appointmentId &&
  //                     d.visitorId === visitor.visitorId
  //                 );

  //                 if (matchingVisitor) {
  //                   return {
  //                     ...visitor,
  //                     lastVisitStatus: 3,
  //                     lastVisitStatusDatetime: new Date(),
  //                   };
  //                 }
  //                 return visitor;
  //               }),
  //             };
  //           }
  //           return appointment;
  //         })
  //       );
  //       setInfo(t("Visitor checked out successfully"));
  //     })
  //     .catch((error) => {
  //       setError(error);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //       setOpenConfirmModal(false);
  //     });
  // };

  const handleCheckinClick = (visitorId: any) => {
    setIsLoading(true);
    checkinVisitor(visitorId)
      .then(() => {
        setAppointments((prevAppointments: any) => {
          return prevAppointments.map((appointment: any) => ({
            ...appointment,
            visitors: appointment.visitors.map((visitor: any) => {
              if (visitor.visitorId === visitorId) {
                return {
                  ...visitor,
                  lastVisitStatus: 2,
                  lastVisitStatusDatetime: new Date(),
                };
              }
              return visitor;
            }),
          }));
        });
        setInfo(t("Visitor checked in successfully"));
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCheckoutClick = (visitorId: any) => {
    setSelectedVisitorId(visitorId);
    setOpenConfirmModal(true);
  };

  const handleConfirmYes = () => {
    setIsLoading(true);
    checkoutVisitor(selectedVisitorId)
      .then(() => {
        setAppointments((prevAppointments: any) => {
          return prevAppointments.map((appointment: any) => ({
            ...appointment,
            visitors: appointment.visitors.map((visitor: any) => {
              if (visitor.visitorId === selectedVisitorId) {
                return {
                  ...visitor,
                  lastVisitStatus: 3,
                  lastVisitStatusDatetime: new Date(),
                };
              }
              return visitor;
            }),
          }));
        });
        setInfo(t("Visitor checked out successfully"));
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
        setOpenConfirmModal(false);
      });
  };

  const handleConfirmNo = () => {
    setOpenConfirmModal(false);
  };

  return (
    <>
      <ConfirmModal
        open={openConfirmModal}
        onYesClick={handleConfirmYes}
        onNoClick={handleConfirmNo}
        message={t("You want to check out this visitor?")}
      />
      <DataGridDX
        getRowId={(row: any) => row.visitorId}
        columns={visitorColumns}
        rows={data}
        checkboxSelection={false}
        disableRowSelectionOnClick={true}
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "inherit", // Or 'transparent' or whatever color you'd like
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
        }}
        rowsPerPage={data.length}
        hideFooter
        loading={isLoading}
      />
    </>
  );
};

export default VisitorGrid;
