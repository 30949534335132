import useSecureAPI from "../hooks/usesecureapi";

const useFolderService = () => {
  const secureAPI = useSecureAPI();

  const getFolders = async () => {
    const response = await secureAPI.get(`dms/Folder/`);
    return response.data;
  };

  const getFolderByID = async (id: number) => {
    const response = await secureAPI.get(`dms/Folder/${id}`);
    return response.data;
  };

  const getFolderTreeByCabinet = async (id: number) => {
    const response = await secureAPI.get(`dms/Folder/treeforcabinet/${id}`);
    return response.data;
  };

  const getFolderContent = async (id: number) => {
    const response = await secureAPI.get(`dms/Folder/content/${id}`);
    return response.data;
  };

  const addFolder = async (data: any) => {
    const response = await secureAPI.post(`dms/Folder/`, data);
    return response.data;
  };

  const updateFolder = async (data: any) => {
    const response = await secureAPI.put(`dms/Folder/`, data);
    return response.data;
  };

  const moveFolder = async (data: any) => {
    const response = await secureAPI.put(`dms/Folder/move`, data);
    return response.data;
  };

  const copyFolder = async (data: any) => {
    const response = await secureAPI.post(`dms/Folder/copy`, data);
    return response.data;
  };

  const archiveFolder = async (id: number) => {
    const response = await secureAPI.put(`dms/Folder/archive/${id}`);
    return response.data;
  };

  const deleteFolder = async (id: number) => {
    const response = await secureAPI.delete(`dms/Folder/${id}`);
    return response.data;
  };

  const restoreFolder = async (id: number) => {
    const response = await secureAPI.put(`dms/Folder/unarchive/${id}`);
    return response.data;
  };

  const bulkCopyFolder = async (data: any) => {
    const response = await secureAPI.post(`dms/Folder/bulkcopy`, data);
    return response.data;
  };

  const bulkMoveFolder = async (data: any) => {
    const response = await secureAPI.put(`dms/Folder/bulkmove`, data);
    return response.data;
  };

  const bulkDeleteFolder = async (data: any) => {
    const response = await secureAPI.put(`dms/Folder/bulkarchive`, data);
    return response.data;
  };

  return {
    getFolders,
    getFolderByID,
    getFolderTreeByCabinet,
    getFolderContent,
    addFolder,
    updateFolder,
    moveFolder,
    copyFolder,
    archiveFolder,
    deleteFolder,
    restoreFolder,
    bulkCopyFolder,
    bulkMoveFolder,
    bulkDeleteFolder,
  };
};

export default useFolderService;
