import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ListPageDX from "../../../components/business/listpagedx";
import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";
import {
  concatenateNameWithRole,
  DateFormatter,
} from "../../../shared/globals";
import useUserDelegationService from "../../../shared/services/userdelegationservice";
import UserDelegationModal from "../../../components/editmodals/userdelegationmodal";

const Delegations = () => {
  const { t, i18n } = useTranslation();
  const { setInfo, setError } = useNotificationContext();
  const { getUserDelegations, deleteUserDelegation } =
    useUserDelegationService();
  const [userDelegationId, setUserDelegationId] = useState<any>(null);
  const [userDelegations, setUserDelegations] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId, userId } =
    userData;

  const languageIsEn = i18n.language === "en";

  const columns: GridColDef[] = [
    {
      field: "delegateFullName",
      headerName: t("Full Name"),
      flex: 1,
    },
    { field: "startDate", headerName: t("Start Date"), flex: 1 },
    { field: "endDate", headerName: t("End Date"), flex: 1 },
  ];

  const getData = () => {
    setIsLoading(true);

    getUserDelegations(userId) //fetch user data from APi
      .then((delegations) => {
        const preprocessedData = delegations.map((delegation: any) => {
          return {
            ...delegation,
            delegateFullName: concatenateNameWithRole(delegation.toUser),
            startDate: DateFormatter(
              delegation.startDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
            endDate: DateFormatter(
              delegation.endDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
          };
        });

        setRows(preprocessedData);
        setUserDelegations(preprocessedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteUserDelegation(id)
      .then(() => {
        setInfo(t("User Delegation deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (user: any) => {
    setUserDelegationId(user.userDelegationId);
    setOpen(!open);
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setUserDelegationId(null);
    setOpen(!open);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(userDelegations);
      return;
    }
    const newRows = userDelegations.filter(
      ({ delegateFullName, startDate, endDate }: any) =>
        delegateFullName.toLowerCase().includes(value.toLowerCase()) ||
        startDate.includes(value.toLowerCase()) ||
        endDate.includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  useEffect(() => {
    getData();
  }, [i18n.language]);
  return (
    <>
      {open && (
        <UserDelegationModal
          open={open}
          handleClose={onClose}
          userDelegationId={userDelegationId}
          delegations={userDelegations}
        />
      )}

      <ListPageDX
        listTitle={t("Delegations")}
        name={t("Delegation")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.userDelegationId}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={onEdit}
        onDelete={onDelete}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default Delegations;
