import { useTranslation } from "react-i18next";
import ListPageDX from "../../../components/business/listpagedx";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import DocumentTemplateModal from "../../../components/editmodals/tools/documenttemplatemodal";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import useDocumentTemplateService from "../../../shared/services/documenttemplateservice";
import {
  concatenateNameWithRole,
  DateTimeFormatter,
} from "../../../shared/globals";
import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";
import { useNavigate } from "react-router-dom";

const DocumentTemplates = (props: any) => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const navigate = useNavigate();
  const { getDocumentTemplates, deleteDocumentTemplate } =
    useDocumentTemplateService();

  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { setInfo, setError } = useNotificationContext();

  const [rows, setRows] = useState<any>([]);
  const [documentTemplates, setDocumentTemplates] = useState<any>([]);

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      flex: 1,
    },

    {
      field: "createdDate",
      headerName: `${t("Created At")}`,
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: `${t("Last Modified At")}`,
      flex: 1,
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,

      valueGetter: (params: any) => {
        return (
          params.row.createdByUser &&
          concatenateNameWithRole(params.row.createdByUser)
        );
      },
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const toPreview = (data: any) => {
    navigate(`/cts/documenttemplate`, {
      state: {
        id: data.documentTemplateId,
      },
    });
  };

  const getData = () => {
    setIsLoading(true);
    getDocumentTemplates()
      .then((documentTemplates) => {
        const preprocessedData = documentTemplates.map((data: any) => {
          return {
            ...data,
            createdDate: DateTimeFormatter(
              data.createdDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
            modifiedDate: DateTimeFormatter(
              data.modifiedDate,
              calendarId,
              gregorianDateFormatId,
              hijriDateFormatId
            ),
          };
        });

        setDocumentTemplates(preprocessedData);
        setRows(preprocessedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteDocumentTemplate(id)
      .then(() => {
        setInfo(t("Document template deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  const toCreate = () => {
    // setOpen(!open);
    navigate(`/cts/documenttemplate`, {
      state: {
        id: null,
      },
    });
  };
  const onClose = (refreshPage: boolean = false) => {
    setOpen(false);
    if (refreshPage) getData();
  };
  useEffect(() => {
    getData();
  }, [i18n.language]);
  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(documentTemplates);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = documentTemplates.filter(
      ({ enName, arName, createdByUser }: any) =>
        languageIsEn
          ? enName?.toLowerCase().includes(lowercasedValue) ||
            createdByUser?.enFullName?.toLowerCase().includes(lowercasedValue)
          : arName?.includes(value) ||
            createdByUser?.arFullName?.includes(value)
    );
    setRows(newRows);
  };

  return (
    <>
      {open && (
        <DocumentTemplateModal
          open={open}
          handleClose={onClose}
          documentTemplateData={undefined}
        />
      )}
      <ListPageDX
        listTitle={t("Document Templates")}
        name={t("Document Templates")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.documentTemplateId}
        isLoading={isLoading}
        toCreate={toCreate}
        onEdit={toPreview}
        onDelete={onDelete}
        editLabel={t("Preview")}
        hideColumnsOption={true}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default DocumentTemplates;
