import { Dialog, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileViewer from "../../business/fileviewer";
import { useState, useEffect } from "react";
import { useNotificationContext } from "../../../context/notificationcontext";
import { getContentFromPdf } from "../../../shared/pdfoperations";
import useDocumentVersionService from "../../../shared/services/documentversionservice";
import Loading from "../../loading";

const VersionViewerModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const { addDocumentVersion } = useDocumentVersionService();

  const [isLoading, setIsLoading] = useState(false);

  const toUpdate = async (file: any) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("DocumentId", props.document.documentId);
    formData.append("File", file);

    getContentFromPdf(file)
      .then((content) => {
        formData.append("ContentAsText", content);
        saveDocument(formData);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  const saveDocument = (document: any) => {
    addDocumentVersion(document)
      .then(() => {
        setInfo(t("Document updated successfully"));
        props.handleClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      fullScreen
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogContent>
        {isLoading && <Loading styles={{ height: "100%", width: "100%" }} />}
        <FileViewer
          fileName={props.fileName}
          fileURL={props.document.downloadURL}
          fileType={props.document.fileExtension.split(".")[1]}
          toUpdate={toUpdate}
          sideInfo={{
            "Active Version": props.document.versionNumber
          }}
          onCloseClick={props.handleClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default VersionViewerModal;