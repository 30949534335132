import useSecureAPI from "../hooks/usesecureapi";

const useCountryService = () => {
  const secureAPI = useSecureAPI();

  const getCountries = async () => {
    const response = await secureAPI.get(`vms/Country`);
    return response.data;
  };

  const getCountrtById = async (id: number) => {
    const response = await secureAPI.get(`vms/Country/${id}`);
    return response.data;
  };



  return {
    getCountries,
    getCountrtById,
    
  };
};

export default useCountryService;