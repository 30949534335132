import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import CorrespondenceIcon from "@mui/icons-material/ArticleOutlined";
import ReportsIcon from "@mui/icons-material/PrintOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ViewListIcon from "@mui/icons-material/ViewList";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";

import {
  CategoryOutlined,
  ContactPageOutlined,
  DomainOutlined,
  GroupOutlined,
  LabelImportantOutlined,
  PrivacyTipOutlined,
  Person,
  StorageOutlined,
  FolderSharedOutlined,
  MediationOutlined,
  TypeSpecimenOutlined,
  Groups2Outlined,
  AssignmentIndOutlined,
  CreditScoreOutlined,
  LanOutlined,
  WhereToVoteOutlined,
} from "@mui/icons-material";
import i18n from "../../multilingual/i18n";
import { useConfigContext } from "../../../context/configcontext";
import { useAuthContext } from "../../../context/authcontext";

const MenuItems = (props: any) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { MODULES } = useConfigContext();
  const { isUserAdmin, userData } = useAuthContext();

  const [open, setOpen] = useState(props ? props.open : false);
  const [activeIndex, setActiveIndex] = useState(0);

  interface IMenuItem {
    id: number;
    text: string;
    icon: JSX.Element;
    link?: string | undefined;
    links?: IMenuItem[] | undefined;
  }

  const CTSMenuItems = [
    {
      id: 1,
      text: t("Dashboard"),
      icon: <InsertChartOutlinedIcon />,
      link: "/cts/dashboard",
    },
    {
      id: 2,
      text: t("Correspondence"),
      icon: <CorrespondenceIcon />,
      link: "/cts/correspondence",
    },
    {
      id: 3,
      text: t("Delegations"),
      icon: <Groups2Outlined />,
      link: "/cts/delegations",
    },
  ];

  const CTSSettingsMenuItems = {
    id: 6,
    text: t("Settings"),
    icon: <SettingsApplicationsOutlinedIcon />,
    links: [
      {
        id: 602,
        text: t("Category"),
        icon: <CategoryOutlined />,
        link: "/cts/category",
      },
      {
        id: 603,
        text: t("Contact Types"),
        icon: <ContactPageOutlined />,
        link: "/cts/contacttypes",
      },
      {
        id: 604,
        text: t("Correspondence Types"),
        icon: <ListAltOutlinedIcon />,
        link: "/cts/correspondencetypes",
      },
      {
        id: 605,
        text: t("Domain Types"),
        icon: <DomainOutlined />,
        link: "/cts/domaintypes",
      },

      {
        id: 607,
        text: t("Entities"),
        icon: <ViewListIcon />,
        link: "/cts/entities",
      },
      {
        id: 608,
        text: t("Entity Types"),
        icon: <ViewListIcon />,
        link: "/cts/entitytypes",
      },
      {
        id: 609,
        text: t("Importance Levels"),
        icon: <LabelImportantOutlined />,
        link: "/cts/importancelevels",
      },
      {
        id: 610,
        text: t("Privacy Levels"),
        icon: <PrivacyTipOutlined />,
        link: "/cts/privacylevels",
      },
      {
        id: 611,
        text: t("Reference Types"),
        icon: <FormatQuoteIcon />,
        link: "/cts/referencetypes",
      },
      {
        id: 612,
        text: t("Routing Groups"),
        icon: <AccountTreeIcon />,
        link: "/cts/routinggroup",
      },
      {
        id: 613,
        text: t("Document Templates"),
        icon: <StorageOutlined />,
        link: "/cts/templates",
      },
      {
        id: 614,
        text: t("Users"),
        icon: <Person />,
        link: "/users",
      },
      {
        id: 615,
        text: t("User Groups"),
        icon: <GroupOutlined />,
        link: "/usergroup",
      },
      {
        id: 616,
        text: t("User Roles"),
        icon: <AssignmentIndOutlined />,
        link: "/userroles",
      },
    ],
  };

  const DMSMenuItems = [
    {
      id: 2,
      text: t("Documents"),
      icon: <SourceOutlinedIcon />,
      link: "/dms/documents",
    },
  ];

  const DMSSettingsMenuItems = {
    id: 4,
    text: t("Settings"),
    icon: <SettingsApplicationsOutlinedIcon />,
    links: [
      {
        id: 4011,
        text: t("Cabinets"),
        icon: <StorageOutlined />,
        link: "/dms/cabinets",
      },
      {
        id: 4012,
        text: t("Structure Templates"),
        icon: <MediationOutlined />,
        link: "/dms/structuretemplates",
      },
      {
        id: 4013,
        text: t("Profiles"),
        icon: <FolderSharedOutlined />,
        link: "/dms/documentprofiles",
      },

      {
        id: 4014,
        text: t("Document Types"),
        icon: <TypeSpecimenOutlined />,
        link: "/dms/documenttypes",
      },
      {
        id: 4015,
        text: t("Categories"),
        icon: <CategoryOutlined />,
        link: "/dms/documentcategories",
      },
      {
        id: 4017,
        text: t("Users"),
        icon: <Person />,
        link: "/users",
      },
    ],
  };

  const VMSMenuItems = [
    {
      id: 1,
      text: t("Dashboard"),
      icon: <InsertChartOutlinedIcon />,
      link: "/vms/dashboard",
    },
    {
      id: 2,
      text: t("Appointments"),
      icon: <CreditScoreOutlined />,
      link: "/vms/appointments",
    },
    {
      id: 3,
      text: t("Check Ins"),
      icon: <WhereToVoteOutlined />,
      link: "/vms/checkins",
    },
  ];

  const VMSSettingsMenuItems = {};
  const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);

  useEffect(() => {
    const pathName = location.pathname.substring(1);
    let currentItem: any = null;

    menuItems.forEach((i) => {
      if (i.link && i.link === pathName) {
        currentItem = i;
      } else if (i.links && currentItem == null) {
        currentItem = i.links.find((si) => si.link === pathName);
      }
    });

    if (currentItem) setActiveIndex(currentItem.id);
  }, [location.pathname, MODULES, i18n.language]);

  useEffect(() => {
    if (props) setOpen(props?.open);
  }, [props?.open]);

  useEffect(() => {
    menuSetup();
  }, [userData?.module, i18n.language]);

  const menuSetup = () => {
    userData?.module?.includes("DMS")
      ? setMenuItems(DMSMenuItems)
      : userData?.module?.includes("VMS")
      ? setMenuItems(VMSMenuItems)
      : setMenuItems(CTSMenuItems);

    if (isUserAdmin())
      setMenuItems((oldMenuItems) => {
        const newItems = oldMenuItems.slice();

        if (userData?.module?.includes("DMS")) {
          newItems.push({
            id: 3,
            text: t("DMS Reports"),
            icon: <ReportsIcon />,
            link: "/dms/reports",
          });
          newItems.push(DMSSettingsMenuItems);
        }

        // making reports and settings menu items visible for only admin users
        if (userData?.module?.includes("CTS")) {
          newItems.push({
            id: 5,
            text: t("Reports"),
            icon: <ReportsIcon />,
            link: "/cts/reports",
          });
          newItems.push(CTSSettingsMenuItems);
        }

        if (userData?.module?.includes("VMS")) {
          newItems.push({
            id: 4,
            text: t("Workflows"),
            icon: <LanOutlined />,
            link: "/vms/workflows",
          });
        }

        return newItems;
      });
  };

  return (
    <List
      sx={{
        px: 1,
        flex: "auto",
        overflowY: "auto",
        overflowX: open ? "auto" : "hidden",
      }}
      dir={i18n.dir()}
    >
      {menuItems.map((item: any, index) => {
        if (item.link)
          return (
            <MenuItem
              key={`menuItem_${index}`}
              item={item}
              open={open}
              activeIndex={activeIndex}
              onClick={props.onClick}
            />
          );
        else
          return (
            <SubMenus
              key={`subMenu_${index}`}
              item={item}
              menuIsOpen={open}
              activeIndex={activeIndex}
              onClick={props.onClick}
            />
          );
      })}
    </List>
  );
};

const MenuItem = ({ item, open, activeIndex, onClick }: any) => {
  const activeBgColor = "#395d41";
  const activeTextColor = "#bba20b";
  const defaultBgColor = "transparent";
  const defaultTextColor = "white";

  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <ListItem
      key={item.text}
      disablePadding
      sx={{
        display: "block",
        borderRadius: 1,
        my: 2,
        background: item.id === activeIndex ? activeBgColor : defaultBgColor,
        color: item.id === activeIndex ? activeTextColor : defaultTextColor,
      }}
    >
      <ListItemButton
        sx={{
          height: 46,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          color: "inherit",
        }}
        onClick={() => {
          navigate(item.link);
          if (onClick) {
            onClick();
          }
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: i18n.dir() === "ltr" ? (open ? 3 : "auto") : "auto",
            ml: i18n.dir() === "rtl" ? (open ? 3 : "auto") : "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          sx={{
            opacity: open ? 1 : 0,
            textAlign: "initial",
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const SubMenus = ({ item, menuIsOpen, activeIndex }: any) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);

  useEffect(() => {
    if (!menuIsOpen) setOpenSubMenu(false);
  }, [menuIsOpen]);

  const handleClick = () => {
    setOpenSubMenu(!openSubMenu);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          color: "white",
          padding: "8px 20px",
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: i18n.dir() === "ltr" ? (menuIsOpen ? 3 : "auto") : "auto",
            ml: i18n.dir() === "rtl" ? (menuIsOpen ? 3 : "auto") : "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          sx={{
            opacity: menuIsOpen ? 1 : 0,
            textAlign: i18n.dir() === "rtl" ? "right" : "left",
          }}
        />
        {menuIsOpen && (openSubMenu ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={openSubMenu} timeout="auto" unmountOnExit sx={{ pl: 2 }}>
        <List component="div" disablePadding>
          {item.links.map((item: any, index: number) =>
            item.link ? (
              <MenuItem
                key={`subMenu_menuItem_${index}`}
                item={item}
                index={index}
                open={openSubMenu}
                activeIndex={activeIndex}
              />
            ) : (
              <SubMenus
                key={`subMenu_${index}`}
                item={item}
                menuIsOpen={openSubMenu}
                activeIndex={activeIndex}
              />
            )
          )}
        </List>
      </Collapse>
    </>
  );
};

export default MenuItems;
